import { useState } from "react";

const BusinessHours = ({ onChange }) => {

    const [businessHours, setBusinessHours] = useState({
        monday: { isOpen: true, open: '09:00', close: '17:00' },
        tuesday: { isOpen: true, open: '09:00', close: '17:00' },
        wednesday: { isOpen: true, open: '09:00', close: '17:00' },
        thursday: { isOpen: true, open: '09:00', close: '17:00' },
        friday: { isOpen: true, open: '09:00', close: '17:00' },
        saturday: { isOpen: false, open: '09:00', close: '17:00' },
        sunday: { isOpen: false, open: '09:00', close: '17:00' }
    });

    const handleChange = (day, field, value) => {
        const updateHours = {
            ...businessHours,
            [day]: {
                ...businessHours[day],
                [field]: value
            }
        }
        
        setBusinessHours(updateHours);
        onChange(updateHours);
    }



    return (
        <div>
            {
                Object.entries(businessHours).map(([day, hours]) => (
                    <div key={day} className="flex items-center space-x-4 p-2 border-gray-900 border-[1px] mb-2 rounded max-md:overflow-scroll">
                        <div className="w-24  capitalize text-gray-700">{day}</div>
                        <label className="flex items-center ">
                            <input
                                type="checkbox"
                                checked={hours.isOpen}
                                onChange={(e) => handleChange(day, 'isOpen', e.target.checked)}
                                className="mr-2"
                            />
                            Open
                        </label>
                        {
                            hours.isOpen && (
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="time"
                                        value={hours.open}
                                        onChange={(e) => handleChange(day, 'open', e.target.value)}
                                        className="border-gray-900 border-[1px] rounded p-1"
                                    />
                                    <span>to</span>
                                    <input
                                        type="time"
                                        value={hours.close}
                                        onChange={(e) => handleChange(day, 'close', e.target.value)}
                                        className="border-gray-900 border-[1px] rounded p-1"
                                    />
                                </div>

                            )
                        }
                    </div>
                ))
            }
        </div>
    )
}

export default BusinessHours