import React, { useEffect, useState } from "react";
import { API_URL } from "../../App";
import { token } from "../home/Home";
import { format } from "timeago.js";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [notifCount, setNotifCount] = useState(null);
  const [page, setPage] = useState(1);
  let limit = 10;

  const getNotification = (page, limit) => {
    fetch(`${API_URL}/notification?pageNumber=${page}&limit=${limit}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setNotifications(data?.notifications);
        setNotifCount(data?.total);
      });
  };
  useEffect(() => {
    getNotification(page, limit);
  }, [page, limit]);

  const SeenNotification = (id) => {
    fetch(`${API_URL}/notification/seen?_id=${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        getNotification(page, limit);
      })
      .then((data) => { })
      .catch((err) => {
        alert(err.message);
      });
  };

  const handleNext = () => {
    if (page < Math.ceil(notifCount / limit)) {
      setPage(page + 1);
      getNotification(page + 1, limit); // Fetch data for the next page
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
      getNotification(page - 1, limit); // Fetch data for the previous page
    }
  };

  return (
    <>
      <div className="main-content-wrapper d-flex flex-column">
        <div className="content-page-box-area">
          <div className="all-notifications-body">
            <div className="all-notifications-header d-flex justify-content-between align-items-center">
              <h3>{notifCount} Notifications</h3>
            </div>

            {notifications.length === 0 ? (
              <p>No notification</p>
            ) : (
              notifications.map((notification) => (
                <div
                  key={notification?._id}
                  className="item d-flex justify-content-between align-items-center"
                  style={{
                    backgroundColor: notification.seen ? "white" : "aliceblue",
                    padding: "5px",
                  }}
                >
                  <div
                    className="figure"
                    onClick={() => SeenNotification(notification._id)}
                  >
                    {notification.message === "Sent you a friend request" ? (
                      <Link to={`/FriendRequest`}>
                        <img
                          src={notification.senderImage}
                          className="rounded-circle"
                          alt="img"
                        />
                      </Link>
                    ) : notification.message === "Commented on your post" ? (
                      <Link to={`/profile`}>
                        <img
                          src={notification.senderImage}
                          className="rounded-circle"
                          alt="img"
                        />
                      </Link>
                    ) : notification.message === "Liked your post" ? (
                      <Link to={`/profile`}>
                        <img
                          src={notification.senderImage}
                          className="rounded-circle"
                          alt="img"
                        />
                      </Link>
                    ) : notification.message.indexOf(
                      "Liked your page" || "Likeed your page"
                    ) === 0 ? (
                      <Link to={`/user/page/${notification.pageId}`}>
                        <img
                          src={notification.senderImage}
                          className="rounded-circle"
                          alt="img"
                        />
                      </Link>
                    ) :
                      notification.message.indexOf(
                        "sents you a message on"
                      ) === 0 && notification.notificationType === "Page" ? (
                        <Link to={`/messenger/user/page/${notification.pageId}`}>
                          <img
                            src={notification.senderImage}
                            className="rounded-circle"
                            alt="img"
                          />
                        </Link>
                      ) : notification.message === "sents you a message" && notification.notificationType === "User" ? (
                        <Link to={`/messenger/page/${notification.senderId}`}>
                          <img
                            src={notification.senderImage}
                            className="rounded-circle"
                            alt="img"
                          />
                        </Link>
                      ) : notification.message === "Liked your post in your page" && notification.notificationType === "Page" ? (
                        <Link to={`/user/page/${notification.pageId}`}>
                          <img
                            src={notification.senderImage}
                            className="rounded-circle"
                            alt="img"
                          />
                        </Link>
                      ) : notification.message === "Commented on your post in your page" && notification.notificationType === "Page" ? (
                        <Link to={`/user/page/${notification.pageId}`}>
                          <img
                            src={notification.senderImage}
                            className="rounded-circle"
                            alt="img"
                          />
                        </Link>
                      ) :
                        notification.message.indexOf(
                          "posted review on your"
                        ) === 0 && notification.notificationType === "Page" ? (
                          <Link to={`/user/page/${notification.pageId}`}>
                            <img
                              src={notification.senderImage}
                              className="rounded-circle"
                              alt="img"
                            />
                          </Link>
                        )
                          : null}
                  </div>
                  <div className="text cursor-pointer">
                    <h4 onClick={() => SeenNotification(notification._id)}>
                      {notification.message === "Sent you a friend request" ? (
                        <Link to={`/FriendRequest`}>
                          {notification.senderName}
                        </Link>
                      ) : notification.message === "Commented on your post" ? (
                        <Link to={`/profile`}>{notification.senderName}</Link>
                      ) : notification.message === "Liked your post" ? (
                        <Link to={`/profile`}>{notification.senderName}</Link>
                      ) : notification.message.indexOf(
                        "Liked your page" || "Likeed your page"
                      ) === 0 ? (
                        <Link to={`/page/${notification._id}`}>
                          {notification.senderName}
                        </Link>
                      ) :
                        notification.message.indexOf("sents you a message on") === 0 && notification.notificationType === "Page" ? (
                          <Link to={`/messenger/user/page/${notification.pageId}`}>
                            {notification.senderName}
                          </Link>
                        )
                          : notification.message === "sents you a message" && notification.notificationType === "User" ? (
                            <Link to={`/messenger/page/${notification.senderId}`}>
                              {notification.senderName}
                            </Link>
                          ) : notification.message === "Liked your post in your page" && notification.notificationType === "Page" ? (
                            <Link to={`/user/page/${notification.pageId}`}>
                              {notification.senderName}
                            </Link>
                          ) : notification.message === "Commented on your post in your page" && notification.notificationType === "Page" ? (
                            <Link to={`/user/page/${notification.pageId}`}>
                              {notification.senderName}
                            </Link>
                          ) :
                            notification.message.indexOf(
                              "posted review on your"
                            ) === 0 && notification.notificationType === "Page" ? (
                              <Link to={`/user/page/${notification.pageId}`}>
                                {notification.senderName}
                              </Link>
                            )
                              : null}
                    </h4>
                    <span>{notification.message}</span>
                    <span className="main-color">
                      {format(notification.date)}
                    </span>
                  </div>
                  <div className="icon">
                    <span className="cursor-pointer">
                      <i className="flaticon-x-mark" onClick={() => SeenNotification(notification._id)}></i>
                    </span>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="mt-3 d-flex justify-content-center align-items-center">
            <Button
              style={{
                backgroundColor: "white",
                color: "black",
                height: "7vh",
                marginRight: "5px",
              }}
              disabled={page <= 1}
              onClick={handlePrev}
            >
              <i className="fa-solid fa-angle-left"></i>
            </Button>
            <Button
              style={{
                backgroundColor: "blue",
                color: "white",
                height: "7vh",
                marginRight: "5px",
              }}
              disabled
            >
              {page}
            </Button>
            <Button
              style={{
                backgroundColor: "white",
                color: "black",
                height: "7vh",
              }}
              disabled={
                page >= Math.ceil(notifCount / limit) || notifCount === 0
              }
              onClick={handleNext}
            >
              <i className="fa-solid fa-angle-right"></i>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
