import React, { useEffect, useState, useRef } from "react";
import Main from "../home/Main";
import LeftSide from "../home/LeftSide";
import RightSide from "../home/RightSide";
import Footer from "../common/Footer";
import Friend from "../friend/Friend";
import { API_URL, toastFunction } from "../../App";
import { token } from "../home/Home";
import About from "./About";
import { Link, useNavigate } from "react-router-dom";
import Loading from "./Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../Page-redux/commonPageReducer/action";
import axios from "axios";

export default function Profile() {
  const [showTab, setShowTab] = useState("timeline");
  const [details, setDetails] = useState("");
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [allPhoto, setAllPhoto] = useState(true);
  const [profilePic, setProfilePic] = useState("");
  const [imagePost, setImagePost] = useState([]);
  const [videoPost, setVideoPost] = useState([]);
  const [imagesLikeCount, setImagesLikeCount] = useState(0);
  const [imagesCommentCount, setImagesCommentCount] = useState(0);
  const [imagesShareCount, setImagesShareCount] = useState(0);
  const [videoLikeCount, setVideoLikeCount] = useState(0);
  const [videosCommentCount, setVideosCommentCount] = useState(0);
  const [videosShareCount, setVideosShareCount] = useState(0);
  const inputRef = useRef(null);
  const [newImage, setNewImage] = useState();
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const navigate = useNavigate();

  const getProfile = async () => {
    try {
      const response = await fetch(`${API_URL}/profile`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      const data = await response.json();
      setProfilePic(data?.message?.profile?.profileImage?.url);
      setDetails(data?.message);
    } catch (error) {
      console.error("Error fetching profile:", error);
      // Handle the error (e.g., display an error message)
    }
  };
  const getImage = async () => {
    try {
      const response = await fetch(`${API_URL}/get-own-images/post`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      const data = await response.json();
      setImageList(data?.images);
    } catch (error) {
      console.error("Error fetching images:", error);
      // Handle the error (e.g., display an error message)
    }
  };

  const getVideo = () => {
    fetch(`${API_URL}/get-own-video/post`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setVideoList(data?.videos);
      });
  };

  const editProfilePic = () => {
    let fdata = new FormData();
    fdata.set("avatar", newImage);

    fetch(`${API_URL}/profile/picture`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          window.location.reload();
        } else {
          alert(data.message);
        }
      })
      .catch((err) => alert(err.message));
  };

  useEffect(() => {
    getProfile();
    getImage();
    getVideo();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [imagesResponse, videosResponse] = await Promise.all([
        fetch(`${API_URL}/get-own-images/post`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),
        fetch(`${API_URL}/get-own-video/post`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }),
      ]);

      const imagesData = await imagesResponse.json();
      const videosData = await videosResponse.json();
      setImagePost(imagesData.images);
      setVideoPost(videosData.videos);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); //

  useEffect(() => {
    let imageLikes = 0;
    let imageComments = 0;
    let imageShares = 0;
    imagePost?.forEach((ele) => {
      imageLikes += ele?.like.length;
      imageComments += ele?.comment.length;
      imageShares += ele?.share.length;
    });
    setImagesLikeCount(imageLikes);
    setImagesCommentCount(imageComments);
    setImagesShareCount(imageShares);

    let videoLikes = 0;
    let videoComments = 0;
    let videoShares = 0;
    videoPost?.forEach((ele) => {
      videoLikes += ele?.like.length;
      videoComments += ele?.comment.length;
      videoShares += ele?.share.length;
    });
    setVideoLikeCount(videoLikes);
    setVideosCommentCount(videoComments);
    setVideosShareCount(videoShares);
  }, [imagePost, videoPost]);
  
  if (loading) {
    return <Loading />;
  }

  const Logout=()=>{
    axios.get(`${API_URL}/logout`,{
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    }
    })
    .then((res)=>{
      if(res.data.status===true){
        localStorage.clear();
        navigate("/");
        window.location.reload();
      }
    })
    .catch((err)=>{
      toastFunction(err.response.data.message)
    })
  }



  return (
    <>
      <div className="main-content-wrapper d-flex flex-column">
        {/* <Heading /> */}
        <div className="content-page-box-area">
          <div className="my-profile-inner-box">
            <div className="profile-cover-image">
              <a href="#">
                <img src="assets/images/my-profile-bg.jpg" alt="profile-bg" />
              </a>
              <a href="#" className="edit-cover-btn">
                Edit Cover
              </a>
            </div>
            <div className="profile-info-box">
              <div className="inner-info-box d-flex justify-content-between align-items-center">
                <div className="info-image">
                  {!newImage && (
                    <img
                      src={
                        profilePic
                          ? `${profilePic}`
                          : "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892"
                      }
                      alt="image"
                      style={{ width: "200px", height: "200px" }}
                    />
                  )}
                  {newImage && (
                    <img
                      src={URL.createObjectURL(newImage)}
                      alt="image"
                      style={{ width: "200px", height: "200px" }}
                    />
                  )}
                  {newImage && (
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ color: "black" }}
                      onClick={() => editProfilePic()}
                    >
                      Save
                    </button>
                  )}
                  <div className="icon">
                    <input
                      style={{ display: "none" }}
                      ref={inputRef}
                      type="file"
                      onChange={(e) => {
                        setNewImage(e.target.files[0]);
                        // console.log("set image .......", e.target.files[0].name)
                      }}
                      accept="image/png, image/jpeg"
                    />
                    {!newImage && (
                      <i
                        className="flaticon-photo-camera"
                        onClick={handleClick}
                      ></i>
                    )}
                  </div>
                </div>
                <div className="info-text ms-3 lg:relative lg:right-72">
                  <h3 className="font-semibold">{details?.name}</h3>
                </div>
                {/* <div className="info-text ms-3">
                  <h3>user Id: {details?._id}</h3>
                </div> */}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="profile-list-tabs">
                  <ul className="nav nav-tabs" id="myTab">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          showTab == "timeline" ? "active" : ""
                        }`}
                        id="timeline-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="timeline"
                        onClick={() => setShowTab("timeline")}
                      >
                        {" "}
                        Timeline
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          showTab == "about" ? "active" : ""
                        }`}
                        id="about-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="about"
                        onClick={() => setShowTab("about")}
                      >
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          showTab == "friends" ? "active" : ""
                        }`}
                        id="friends-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="friends"
                        onClick={() => setShowTab("friends")}
                      >
                        Friends
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          showTab == "photos" ? "active" : ""
                        }`}
                        id="photos-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="photos"
                        onClick={() => setShowTab("photos")}
                      >
                        Photos
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          showTab == "pages" ? "active" : ""
                        }`}
                        id="photos-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="pages"
                        onClick={() => setShowTab("pages")}
                      >
                        <Link to="/yourpage">Pages</Link>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${showTab == "" ? "active" : ""}`}
                        id="photos-tab"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-controls="photos"
                        onClick={() => Logout()}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
                <ul
                  className="statistics"
                  style={{
                    padding: "0",
                    marginBottom: "0",
                    textAlign: "center",
                  }}
                >
                  <li
                    style={{
                      listStyleType: "none",
                      display: "inline-block",
                      marginRight: "1px",
                      position: "relative",
                      paddingRight: "12px",
                    }}
                  >
                    <span
                      className="item-number"
                      style={{
                        fontSize: "var(--font-size)",
                        color: "var(--black-color)",
                        fontWeight: "600",
                      }}
                    >
                      {imagesLikeCount + videoLikeCount}
                    </span>
                    <span
                      className="item-text"
                      style={{
                        color: "var(--paragraph-color)",
                        display: "block",
                        marginTop: "2px",
                        fontSize: "13px",
                      }}
                    >
                      <i className="flaticon-like" ></i>
                      <span className="px-1 font-bold">Likes</span>
                    </span>
                  </li>
                  <li
                    style={{
                      listStyleType: "none",
                      display: "inline-block",
                      marginRight: "1px",
                      position: "relative",
                      paddingRight: "12px",
                      paddingLeft: "12px",
                    }}
                  >
                    <span
                      className="item-number"
                      style={{
                        fontSize: "var(--font-size)",
                        color: "var(--black-color)",
                        fontWeight: "600",
                      }}
                    >
                      {imagesCommentCount + videosCommentCount}
                    </span>
                    <span
                      className="item-text"
                      style={{
                        color: "var(--paragraph-color)",
                        display: "block",
                        marginTop: "2px",
                        fontSize: "13px",
                      }}
                    >
                      <i className="flaticon-comment"></i>
                      <span className="px-1 font-bold">Comments</span>
                    </span>
                  </li>
                  <li
                    style={{
                      listStyleType: "none",
                      display: "inline-block",
                      marginRight: "1px",
                      position: "relative",
                      // borderRight: "1px solid #707070",
                      paddingRight: "12px",
                      paddingLeft: "12px",
                    }}
                  >
                    <span
                      className="item-number"
                      style={{
                        fontSize: "var(--font-size)",
                        color: "var(--black-color)",
                        fontWeight: "600",
                      }}
                    >
                      {imagesShareCount + videosShareCount}
                    </span>
                    <span
                      className="item-text"
                      style={{
                        color: "var(--paragraph-color)",
                        display: "block",
                        marginTop: "2px",
                        fontSize: "13px",
                      }}
                    >
                      <i className="flaticon-share"></i><span className="px-1 font-bold">Share</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                showTab == "timeline" && "show active"
              } `}
              role="tabpanel"
            >
              <div className="row">
                <RightSide />
                <Main />
                <LeftSide />
              </div>
            </div>

            <div
              className={`tab-pane fade ${
                showTab == "about" && "show active"
              } `}
              role="tabpanel"
              onClick={() => setShowTab("about")}
            >
              <About />
            </div>

            <div
              className={`tab-pane fade ${
                showTab == "friends" && "show active"
              } `}
              role="tabpanel"
              onClick={() => setShowTab("friends")}
            >
              <Friend />
            </div>
            {/* <div className={`tab-pane fade ${showTab == "pages" && "show active"} `} role="tabpanel" onClick={() => setShowTab("pages")}>
                          <Page/>
                        </div> */}
            <div
              className={`tab-pane fade ${
                showTab == "photos" && "show active"
              } `}
              role="tabpanel"
              onClick={() => setShowTab("photos")}
            >
              <div className="photos-inner-box-style d-flex justify-content-between align-items-center">
                <div className="title">
                  <h3>Photos</h3>
                  <span>{imageList?.length}</span>
                </div>

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${allPhoto ? "active" : ""}`}
                      id="all-photos-tab"
                      data-bs-toggle="tab"
                      href="#all-photos"
                      role="tab"
                      aria-controls="all-photos"
                      onClick={() => {
                        setAllPhoto(true);
                      }}
                    >
                      All Photos
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${allPhoto ? "" : "active"}`}
                      id="photos-albums-tab"
                      data-bs-toggle="tab"
                      href="#photos-albums"
                      role="tab"
                      aria-controls="photos-albums"
                      onClick={() => {
                        setAllPhoto(false);
                      }}
                    >
                      {" "}
                      Videos
                    </a>
                  </li>
                </ul>
                <div className="photos-search-box">
                  <form>
                    <input
                      type="text"
                      className="input-search"
                      placeholder="Search photos..."
                    />
                    <button type="submit">
                      <i className="ri-search-line"></i>
                    </button>
                  </form>
                </div>
              </div>
              <div className="tab-content" id="myTabContent">
                <div
                  className={`tab-pane fade ${allPhoto ? "show active" : ""}`}
                  id="all-photos"
                  role="tabpanel"
                >
                  <div className="row justify-content-center">
                    {imageList?.length > 0 &&
                      imageList?.map((item) => (
                        <div className="col-lg-3 col-sm-6" key={item._id}>
                          <div className="single-photos-card">
                            <a href="#">
                              <img src={`${item.imageUrl.url}`} alt="image" />
                            </a>

                            <div className="icon">
                              <a href="#">
                                <i className="flaticon-remove-user"></i>
                              </a>
                              <a href="#">
                                <i className="flaticon-download"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="load-more-posts-btn">
                    <a href="#">
                      <i className="flaticon-loading"></i> Load More
                    </a>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${allPhoto ? "" : "show active"}`}
                  id="photos-albums"
                  role="tabpanel"
                >
                  <div className="row justify-content-center">
                    {videoList?.length > 0 &&
                      videoList?.map((item) => (
                        <div className="col-lg-3 col-sm-6" key={item._id}>
                          <div className="single-photos-card">
                            <video width="640" height="360" controls>
                              <source
                                src={item.videoFile.url}
                                type="video/mp4"
                                style={{ height: "400px", width: "400px" }}
                              />
                              Your browser does not support the video tag.
                            </video>
                            <div className="icon">
                              <a href="#">
                                <i className="flaticon-remove-user"></i>
                              </a>
                              <a href="#">
                                <i className="flaticon-download"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="load-more-posts-btn">
                    <a href="#">
                      <i className="flaticon-loading"></i> Load More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
