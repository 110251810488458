import { useState, useEffect } from "react";
import BusinessHours from "./SubFolders/BusinessHours";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { token } from "../../home/Home";
import { API_URL, toastFunction } from "../../../App";
import { useParams } from "react-router-dom";
import { GetUserPage } from "../../../Page-redux/userPageReducer/action";

const UserPageAbout = () => {
  const userPageData = useSelector((state) => state.userPageReducer.data);
  const pageData = userPageData?.find(page => page);
  const {id} = useParams();
  let dispatch=useDispatch()

  
  

  // Initialize state with null values
  const [formData, setFormData] = useState({
    email: "",
    website_url: "",
    mobile: "",
    address: "",
    description: "",
    business_hours: "",
    category: ""
  });
  
  const [show, setShow] = useState(true);

  // Update state when Redux data changes
  useEffect(() => {
    if (pageData) {
      setFormData({
        email: pageData.email || "",
        website_url: pageData.website_url || "",
        mobile: pageData.mobile || "",
        address: pageData.address || "",
        description: pageData.description || "",
        business_hours: pageData.business_hours || "",
        category: pageData.category || ""
      });
    }
  }, [pageData]);

  // Handle input changes
  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const UpdatePageAbout = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/page/about/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }
        }
      );
  
      if (response.data.status === true) {
        toastFunction(response.data.message);
        setShow(true); // Change to true since true means view mode in your component
        await dispatch(GetUserPage(id)); // Await the API call
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || "An error occurred";
      toastFunction(errorMessage);
    }
  };





  return (
    <div className="row">
      <div className="col-lg-5 col-md-12">
        <div className="about-personal-information">
          <div className="about-header d-flex justify-content-between align-items-center">
            <div className="title">Business Information</div>
            <div className="dropdown">
              {show && (
                <i 
                  style={{ cursor: "pointer" }}
                  className="fa-solid fa-pen-to-square cursor-pointer"
                  onClick={() => setShow(!show)}
                ></i>
              )}
              {!show && (
                <i
                  className="fa-solid fa-xmark cursor-pointer"
                  onClick={() => setShow(true)}
                ></i>
              )}
            </div>
          </div>
          {show && (
            <ul className="information-list">
              <li>
                <span className="text-base text-gray-800 font-medium">Category:</span>{" "}
                {pageData?.category || "NA"}
              </li>
              <li>
                <span className="text-base text-gray-800 font-medium">Email:</span>{" "}
                <a href={`mailto:${pageData?.email}`}>
                  <span
                    className="__cf_email__"
                    style={{ color: "gray", fontWeight: 400 }}
                  >
                    {pageData?.email || "NA"}
                  </span>
                </a>
              </li>
              <li>
                <span className="text-base text-gray-800 font-medium">Website URL:</span>{" "}
                {pageData?.website_url || "NA"}
              </li>
              <li>
                <span className="text-base text-gray-800 font-medium">Phone:</span>{" "}
                <a href={`tel:${pageData?.mobile}`}>{pageData?.mobile || "NA"}</a>
              </li>
              <li>
                <span className="text-base text-gray-800 font-medium">Address:</span>{" "}
                {pageData?.address || "NA"}
              </li>
              <li className="md:flex md:space-x-3">
                <span className="text-base text-gray-800 font-medium">Business hours:</span>{" "}
                <ul className="max-sm:mt-5">
                  {["friday", "saturday", "sunday", "monday", "tuesday", "wednesday", "thursday"].map((day) => (
                    <li key={day} className="flex items-center space-x-4">
                      <p className="text-base text-gray-800 font-medium w-24">
                        {day.charAt(0).toUpperCase() + day.slice(1)}
                      </p>
                      <p className="text-base text-gray-600 font-medium">
                        {pageData?.business_hours?.[day]?.isOpen 
                          ? `${pageData.business_hours[day].open} - ${pageData.business_hours[day].close}`
                          : "Close"}
                      </p>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          )}
          {!show && (
            <ul className="information-list">
              <li>
                <div className="text-base text-gray-800 font-medium">Category *</div>
                <input
                  type="text"
                  placeholder="eg: marketing"
                  value={formData.category}
                  onChange={(e) => handleChange("category", e.target.value)}
                  className="border-gray-900 border-[1px] rounded-lg text-center p-2 w-full"
                />
              </li>
              <li>
                <div className="text-base text-gray-800 font-medium">Email *</div>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  className="border-gray-900 border-[1px] rounded-lg text-center p-2 w-full"
                />
              </li>
              <li>
                <div className="text-base text-gray-800 font-medium">Website URL *</div>
                <input
                  type="url"
                  value={formData.website_url}
                  onChange={(e) => handleChange("website_url", e.target.value)}
                  className="border-gray-900 border-[1px] rounded-md text-center p-2 w-full"
                />
              </li>
              <li>
                <div className="text-base text-gray-800 font-medium">Phone *</div>
                <input
                  type="text"
                  value={formData.mobile}
                  onChange={(e) => handleChange("mobile", e.target.value)}
                  className="border-gray-900 border-[1px] rounded-md text-center p-2 w-full"
                />
              </li>
              <li>
                <div className="text-base text-gray-800 font-medium">Address *</div>
                <input
                  type="text"
                  value={formData.address}
                  onChange={(e) => handleChange("address", e.target.value)}
                  className="border-gray-900 border-[1px] rounded-md text-center p-2 w-full"
                />
              </li>
              <li>
                <div className="text-base text-gray-800 font-medium">Business hours *</div>
                <BusinessHours 
                  onChange={(value) => handleChange("business_hours", value)}
                  initialData={formData.business_hours}
                />
              </li>
              <li>
                <div className="text-base text-gray-800 font-medium">Description *</div>
                <textarea
                  value={formData.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                  className="border-gray-900 border-[1px] rounded-lg text-center p-2 w-full"
                />
              </li>
              <li>
                <button className="btn btn-primary" onClick={()=>UpdatePageAbout()}>
                  Save
                </button>
              </li>
            </ul>
          )}
        </div>
      </div>
      <div className="col-lg-7 col-md-12">
        <div className="about-details-information">
          <div className="information-box-content">
            <div className="information-header d-flex justify-content-between align-items-center">
              <div className="title">Description</div>
            </div>
            {
              show && (
                <div className="content">
                <p>{pageData?.description}</p>
              </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPageAbout;