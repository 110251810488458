import { ADD_REALTIME_MESSAGE, getMsg, getUsers, msgError, msgRequest, postMsg } from "./actionType"
const init = {
    isLoading:false,
    isError:false,
    msg:[],
    Users:[]
}


export const reducer = (state=init, {type,payload}) => {
    switch(type){
        case msgRequest:{
            return {...state,isLoading:true}
        }

        case getMsg:{
            return {...state,isLoading:false,isError:false,msg:payload}
        }

        case msgError:{
            return {...state,isError:true}
        }

        case postMsg:{
            return {...state}
        }

        case ADD_REALTIME_MESSAGE:{
           return {...state,isLoading:false,msg:[...state.msg,payload]}
        }

        case getUsers:{
            return {...state,isLoading:false,Users:payload}
        }

        default:{
            return state
        }
    }
}