import React from 'react'
import Upload from './center/Upload'

import AllPost from './center/AllPost'

export default function Main() {
    return (
        <>
            <div className="col-lg-6 col-md-12">
                <div className="news-feed-area">
                    <Upload />
                    <AllPost />

                    <div className="load-more-posts-btn">
                        <a href="#"><i className="flaticon-loading"></i> Load More Posts</a>
                    </div>
                </div>
            </div>
        </>
    )
}
