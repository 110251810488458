import axios from "axios"
import { getMsg, getUsers, msgError, msgRequest, postMsg } from "./actionType"
import { API_URL, toastFunction } from "../../App"
import { token } from "../../component/home/Home"


export const PostMessages = (obj) => (dispatch) => {
    axios.post(`${API_URL}/page/message/create`, obj, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then((res) => {
            dispatch(PostMsg())
        })
        .catch((err) => {
            toastFunction(err)
        })
}

export const GetMessages = (from, to, fromType, toType) => (dispatch) => {
    dispatch(MsgRequest())
    axios.get(`${API_URL}/page/message/get?from=${from}&to=${to}&fromType=${fromType}&toType=${toType}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then((res) => {
            dispatch(GetMsg(res.data.data))
        })
        .catch((err) => {
            dispatch(MsgError())
        })
}

export const GetUsers = (id) => (dispatch) => {
    axios.get(`${API_URL}/page/message/users/${id}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then((res) => {
            dispatch(Get_Users(res.data.data))
        })
        .catch((err) => {

        })
}




export const MsgRequest = () => {
    return { type: msgRequest }
}

export const MsgError = () => {
    return { type: msgError }
}


export const GetMsg = (payload) => {
    return { type: getMsg, payload }
}

export const Get_Users = (payload) => {
    return {type:getUsers,payload}
}

export const PostMsg = () => {
    return { type: postMsg }
}