import axios from "axios";
import { useEffect, useState } from "react"
import { API_URL, toastFunction } from "../../../../App";
import { token } from "../../../home/Home";
import { useParams } from "react-router-dom";
import { GetUserPage } from "../../../../Page-redux/userPageReducer/action";
import { useDispatch, useSelector } from "react-redux";


const VerifyBusiness=()=>{
    const userPageData = useSelector((state) => state.userPageReducer.data);
    const pageData = userPageData?.find(page => page);
    const [business_license ,setBusiness_License] = useState(null);
    const {id} = useParams()
    let dispatch = useDispatch()
    console.log(pageData,"e");
    
     useEffect(() => {
      if (id) {
        dispatch(GetUserPage(id));
      }
    }, [id, dispatch]);

    const UploadBusinessLicense=()=>{
        let formData = new FormData();
        formData.append('business_license',business_license)
        axios.put(`${API_URL}/page/business/license/${id}`,formData,{
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              }
        })
        .then((res)=>{
            if(res.data.status){
            toastFunction(res.data.message);
            }
        })
        .catch((err)=>{
            toastFunction(err.response?.data?.message)
        })
    }


    return (
        <div className="bg-[#f4f7fc] h-screen flex justify-center items-center">
            <div className="w-[60%] max-sm:w-[80%] mx-auto ">
             <div className="rounded-lg shadow-sm bg-white  p-3">
                {
                    pageData?.business_license?
                    (
                        <p className="text-gray-700 p-3">Your document is under process. Please keep patience.</p>
                    ):
                    (
                        <div>
                        <p className="font-semibold text-[17px] text-gray-900 mb-2">Business license</p>
                        {
                            
                            <div className="flex justify-between mb-2">
                                <p className="font-medium text-gray-900"> your_document.pdf</p>
                                <p><i className="fa-solid fa-eye text-xl text-blue-900 cursor-pointer"></i></p>
                            </div>
                        }
                        <div className="border-[1px] border-gray-500 h-32 rounded-md border-dashed">
                            <div>
                                <label htmlFor="file-upload" className="flex justify-center items-center">
                                    <p className="p-2 border-[1px] border-blue-600 border-dashed rounded-full text-sm font-semibold text-blue-600 cursor-pointer relative top-5 mt-3">Upload business license</p>
                                </label>
                                <p className="text-sm text-gray-600 text-center mt-5">Supported Formats: doc, pdf, upto 2 MB</p>
                                <input type="file" id="file-upload" className="hidden"
                                onChange={(e)=>{
                                    setBusiness_License(e.target.files[0])
                                }}
                                />
                            </div>
    
                        </div>
                        {
                            business_license &&
                            (
                                <div className="flex justify-end items-center  border-gray-200 rounded-b dark:border-gray-600 mt-4">
                                    <button className="border-[1px] border-gray-200 h-10 rounded-full text-center w-[80px] max-sm:w-[80px] text-blue-800 font-[600]" onClick={()=>setBusiness_License(null)}>Cancel</button>
                                    <button className="border-[1px] border-gray-200 h-10 rounded-full text-center w-[80px] max-sm:w-[80px] bg-green-700 text-white font-[600] ml-3" onClick={()=>UploadBusinessLicense()}>Save</button>
                                </div>
                            )
                        }
                        </div>
                    )
                }
                   
                </div>

        </div>
        </div>
        
    )
}

export default VerifyBusiness