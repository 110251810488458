import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import axios from "axios"
import { API_URL, toastFunction } from '../../../App';
import { token } from "../../home/Home";
import "../PagePost/imageCard.css";
import { format } from "timeago.js";
import { GetReviews } from "../../../Page-redux/commonPageReducer/action";

const Reviews = () => {
    const [show, setShow] = useState(false)
    const { id } = useParams()
    const [message, setMessage] = useState('')
    const user = useSelector((state) => state.commonPageReducer.current);
    let userPage = useSelector((item) => item.commonPageReducer.otheruserdata);
    const pageData = userPage?.find(page => page);
    let reviewsData = useSelector((item) => item.commonPageReducer.reviews)
    const userPageData = useSelector((state) => state.userPageReducer.data);
    const userpageData = userPageData?.find(page => page);

    let dispatch = useDispatch()
    
    const PostReviews = () => {
        let val = { message: message }
        axios.post(`${API_URL}/reviews/create/${id}`, val, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
            .then((res) => {
                if (res.data.status) {
                    toastFunction(res.data.message)
                    dispatch(GetReviews(id))
                }
            })
            .catch((err) => {
                toastFunction(err.response?.data?.message)
            })
        setMessage('')
        setShow(false)
    }


    return (
        <div className="col-lg-6 mx-auto">
            {
                ((userpageData?.page_admin_id?._id!==user?._id) && (!reviewsData?.find(ele =>  ele?.reviews.find(item=>item?.user?._id === user?._id))) || (userpageData &&(userpageData?.page_admin_id?._id!==user?._id)))
                &&
                (
                    <div>
                        {
                            show &&
                            (
                                <div>
                                    <div className="fixed top-0 left-0 right-0 z-50 bg-gray-600 bg-opacity-50 overflow-y-auto h-screen flex justify-center items-center">
                                        <div className="bg-[#fff] w-[40%] max-sm:w-[95%] rounded-lg p-3 shadow-md  py-4">
                                            <div className="border-b border-gray-300 flex justify-center items-center pb-3">
                                                <p className="text-gray-700 text-base font-semibold">Recommend <span className="text-gray-800 text-[16px]">{pageData?.name}</span></p>
                                            </div>
                                            <div className="flex gap-2 pt-3">
                                                <img
                                                    src={user?.profile?.profileImage?.url}
                                                    alt="user_img"
                                                    className="h-[50px] w-[50px] rounded-full"
                                                />
                                                <textarea
                                                    placeholder="post must be 10 characters long"
                                                    className="w-full border-gray-400 border rounded p-3 placeholder:text-sm"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />

                                            </div>
                                            <div className="mt-4 flex justify-end">
                                                <button className="w-[20%] h-8 border hover:bg-gray-50 rounded-md" onClick={() => setShow(false)}>Cancel</button>
                                                <button className="w-[20%] h-8 bg-blue-500 hover:bg-blue-400 rounded-md text-white ml-2" onClick={() => PostReviews()}>Post</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                        <div>
                            <div className="bg-[#fff] rounded p-3">
                                <p className="text-gray-700 text-base font-semibold">Do you recommend <span className="text-gray-800 text-[16px]">{pageData?.name}</span></p>
                                <div className="mt-4 flex justify-between">
                                    <button className="w-[45%] h-9 bg-gray-200 hover:bg-gray-300 rounded-md" onClick={() => setShow(true)}>Yes</button>
                                    <button className="w-[45%] bg-gray-200 hover:bg-gray-300 rounded-md">No</button>
                                </div>
                            </div>
                            <div className="bg-[#fff] rounded mt-5 p-3 flex justify-center items-center">
                                <p className="text-gray-700 text-base font-semibold">{`${(reviewsData?.length)} reviews` || 'Not yet rated (0 reviews)'}</p>
                            </div>
                        </div>
                    </div>
                )
            }
            <div>
                {
                    reviewsData?.map((ele) => {
                        let data = ele.reviews.find(item => item);

                        return <div className="news-feed news-feed-post mb-3 mt-5 bg-[#fff] rounded p-3">
                            <div className="post-header flex">
                                <div className="image">
                                    <img src={`${data?.user?.profile?.profileImage?.url || "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892"}`}
                                        className="rounded-circle mt-2" style={{ width: "50px" }} alt="img" />
                                </div>
                                <div className="info ms-3">
                                    
                                    <span className="name font-semibold">{data?.user?.name}</span>
                                    <span className="text-sm block mt-2">{format(data?.createdAt)}</span>
                                </div>

                            </div>
                            <div className="post-body mt-4">
                                <p className="text-gray-700">{data?.message}</p>
                            </div>
                        </div>
                    })
                }

            </div>

        </div>
    )
}

export default Reviews