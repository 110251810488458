import { All_Page_Success, Data_Request, Get_Current, Get_Data_Success, get_reviews, Get_userData } from "../actionType"
import { Auth_Success } from "./action"

const init = {
    isError: false,
    isLoading: false,
    otheruserdata: [],
    current:{},
    userData:[],
    reviews:[]
}

export const reducer = (state = init, { type, payload }) => {
    switch (type) {

        case Data_Request: {
            return { ...state, isLoading: true }
        }

        case All_Page_Success: {
            return { ...state, isLoading: false, otheruserdata: payload }
        }

        case Get_Current:{
            return {...state,current:payload}
        }

        case Get_userData:{
            return {...state,userData:payload}
        }

        case get_reviews:{
            return {...state,reviews:payload}
        }

        case Auth_Success:{
            return {...state}
        }

        default: {
            return state
        }

    }


}