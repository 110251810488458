import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../../Page-redux/commonPageReducer/action";
import { format } from "timeago.js";
import { useEffect } from "react";

const PageVisitors = () => {

    let dispatch = useDispatch()

    let userPage = useSelector((item) => item.userPageReducer.data);
    let pageData = userPage?.find(page=>page);
    const user = useSelector((state) => state.commonPageReducer.current);

    useEffect(() => {
        dispatch(getCurrentUser());
      }, [dispatch]);
    
    
    

    return (
        <div className="container">
            <div className="row pb-5 mb-4">
                <p className="mb-3 text-black">Page viewers ({!pageData?.visitors?.find(ele=>ele?.user?._id==user._id) ? pageData?.visitors?.length: pageData?.visitors?.length-1})</p>
                {
                    pageData?.visitors?.length>0? (
                        pageData?.visitors?.map((ele)=>{
                            const admin = ele?.user._id===user._id
                            return (

                              !admin &&  (
                                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0 mt-3">
                                    <div className="card shadow-sm border-0 rounded">
                                        <div className="card-body p-0">
                                            <div style={{ height: "35vh" }}>
                                                <img
                                                    src={ele?.user?.profile?.profileImage?.url}
                                                    alt="visitiors"
                                                    className="w-100 card-img-top"
                                                    style={{ height: "100%", width: "70%" }}
                                                />
                                            </div>
                                            <div className="p-4">
                                                <h5 className="mb-0">{ele?.user?.name}</h5>
                                                <p className="small text-muted">Last-visit ({format(ele?.lastVisit)})</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )
                            )
                            
                           
                        })

                    )
                    :
                    <p className="card shadow-sm">You don't have visitors in your page.</p>
                }
             
            </div>
        </div>

    )
}

export default PageVisitors