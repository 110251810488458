import { Route, Routes, useNavigate } from "react-router-dom"
import ConditionalPage from "./ConditionalPage"
import Page from "../component/Pages/Page"
import UserPage from "../component/Pages/userPages/userPage"
import AllPage from "../component/Pages/otherUserPages/allPage"
import PrivateRoute from "./PrivateRoute"
import Profile from "../component/profile/Profile"
import Friend from "../component/friend/Friend"
import FriendRequest from "../component/friend-request/FriendRequest"
import Notification from "../component/notification/Notification"
import Messenger from "../component/chat/messenger/Messenger"
import { UserProfile } from "../component/userProfile/UserProfile"
import Header from "../component/heading/heading"
import AdminSidebar from "../Admin/AdminSidebar"
import Register from "../component/auth/Register"
import Main from "../video_chat/Main"
import { ForgotPassword } from "../component/auth/ForgotPassword"
import { Otp } from "../component/auth/Otp"
import { VerifyEmail } from "../component/auth/VerifyEmail"
import { VerifyEmailReg } from "../component/auth/VerifyEmailReg"
import VerifyBusiness from "../component/Pages/userPages/SubFolders/VerifyBusiness"
import OtherUserMessages from "../component/Pages/otherUserPages/OtherUserMessages"
import PageMessages from "../component/Pages/userPages/PageMessages"
import Verify2faOtp from "../component/auth/Verify2faOtp"
import Request2fa from "../component/auth/Request2fa"
import PageAccess from "../component/Pages/otherUserPages/PageAccess"




const AllRoute = () => {
    const user = JSON.parse(localStorage.getItem("user"));
      //const user = useSelector((state) => state.commonPageReducer.current);

    
    return (
        <div className="App">
           {user?.role=="user" && user.status=="active" ? <Header /> : ""}

            <Routes>
                <Route path="/" element={<ConditionalPage />} />
                <Route path="/admin" element={<AdminSidebar />} />
                <Route path="/register" element={<Register />} />
                <Route path="/yourpage" element={<PrivateRoute><Page /></PrivateRoute>} />
                <Route path="/user/page/:id" element={<PrivateRoute><UserPage /></PrivateRoute>} />
                <Route path="/verify/business/:id" element={<VerifyBusiness/>}/>
                <Route path="page/:id" element={<PrivateRoute><AllPage /></PrivateRoute>} />
                <Route path="/messenger/page/:id" element={<PrivateRoute><OtherUserMessages/></PrivateRoute>}/>
                <Route path="/messenger/user/page/:id" element={<PrivateRoute><PageMessages/></PrivateRoute>}/>
                <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
                <Route path="/friend" element={<PrivateRoute><Friend /></PrivateRoute>} />
                <Route path="/FriendRequest" element={<PrivateRoute><FriendRequest /></PrivateRoute>} />
                <Route path="/notification" element={<PrivateRoute><Notification /></PrivateRoute>} />
                <Route path="/message" element={<PrivateRoute><Messenger /></PrivateRoute>} />
                <Route path="/chat" element={<PrivateRoute><Messenger /></PrivateRoute>} />
                <Route path='/user/profile/:id' element={<PrivateRoute><UserProfile /></PrivateRoute>} />
                <Route path='/videoCall' element={<Main/>} />
                <Route path="/forgot/Password" element={<ForgotPassword />} />
                <Route path="/verify-otp" element={<Otp />} />
                <Route path="/verify-email" element={<VerifyEmail />} />
                <Route path='/user/email/verification' element={<VerifyEmailReg />} />
                <Route path='/verify-2faotp' element={<Verify2faOtp/>}/>
                <Route path='/request-2fa' element={<Request2fa/>}/>
                <Route path="/page/invitation/accept/:token" element={<PageAccess/>}/>
            </Routes>
        </div>
    )
}

export default AllRoute