import { useNavigate } from "react-router-dom";
import { toastFunction } from "../../App";
import { Authentication_2fa } from "../../Page-redux/commonPageReducer/action";
import { useDispatch, useSelector } from "react-redux";

const Request2fa=()=>{

    const navigate = useNavigate();
    let dispatch =useDispatch();
    const user = useSelector((state) => state.commonPageReducer.current);
    console.log(user,"kk");
    

    const HandleApprove=async()=>{
        let val = {status:"unable"}
        const result = await dispatch(Authentication_2fa(user._id,val))
        if(result.success==true){
          if(result.user.two_FA_status==="unable"){
            toastFunction(result.msg);
            navigate('/');
          }
        }
        else{
          toastFunction(result.msg)
        }
      }
    
      const HandleDeny=async()=>{
        let val = {status:"modified"}
        const result = await dispatch(Authentication_2fa(user._id,val))
        console.log(result,"check");
        
        if(result.success==true){
          if(result.user.two_FA_status==="modified"){
            navigate('/');
            setTimeout(() => {
              window.location.reload();
           }, 1000);
          }
        }
        else{
          toastFunction(result.msg)
        }
      }
    

    return (
        <section className="min-h-screen flex items-center justify-center bg-gray-100">
             <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
             <div className="2fa">
                  <div className="flex justify-center items-center">
                    <p className="">2-Factor Authentication <i className="fa-solid fa-lock "></i></p>
                  </div>
                  <p className="text-blue-500 text-[15px] mt-3 md:m-10">You are requested to implement two factor authentication</p>
                  <div className="flex justify-between mt-5">
                    <button className="w-[37%] bg-red-600 text-white" onClick={HandleDeny}>Deny</button>
                    <button className="w-[37%] py-[5px] bg-green-600 text-white" onClick={HandleApprove}>Approve</button>
                  </div>
                </div>
             </div>
          </section>  
    )
}
export default Request2fa