import axios from "axios"
import { API_URL } from "../../App"
import { token } from "../../component/home/Home"
import { All_Page_Success, auth_success, Data_Request, Get_Current, Get_Data_Success, get_reviews, Get_userData } from "../actionType"



export const GetCommonPage = (type, id) => (dispatch) => {
    dispatch(DataRequest())
    fetch(`${API_URL}/page/get?type=${type}&id=${id}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then((res) => {
            return res.json()
        })
        .then((data) => {
            dispatch(DataSuccess(data.pages))
        })
}


export const getCurrentUser = () => (dispatch) => {
    axios.get(`${API_URL}/current`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then((res) => {
            dispatch(GetCurrent(res.data.user))
        })
        .catch((err) => {
            console.log(err)
        })
}


export const GetReviews = (id) => (dispatch) => {
    axios.get(`${API_URL}/reviews/get/${id}`, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    })
        .then((res) => {


            dispatch(Get_Reviews(res.data.data))
        })
        .catch((err) => {
            console.error(err)
        })
}


export let Authentication_2fa = (id, obj) => (dispatch) => {
   return axios.put(`${API_URL}/update-two-FA-status/${id}`, obj, {
        headers: {
            "Content-Type": "application/json",
        }
    })
        .then((res) => {
            if(res.data.success==true){
                dispatch(Auth_Success())
                return {success:true,user:res.data.user}
            }
            else{
                return {msg:res.data.message}
            }
        })

        .catch((err) => {
            console.error(err)
        })
}




export let DataRequest = () => {
    return { type: Data_Request }
}


export let DataSuccess = (payload) => {
    return { type: All_Page_Success, payload }
}


export let GetCurrent = (payload) => {
    return { type: Get_Current, payload }
}

export let GetUserData = (payload) => {
    return { type: Get_userData, payload }
}

export let Get_Reviews = (payload) => {
    return { type: get_reviews, payload }
}

export let Auth_Success = () => {
    return { type: auth_success }
}