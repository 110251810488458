import React from 'react'

export default function Footer() {
  return (
    <>
    <div className="copyrights-area">
            <div className="container">
                <div className="row align-items-center">
                    <p className='flex justify-center items-center'><i className="ri-copyright-line"></i> <script data-cfasync="false" src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.write(new Date().getFullYear())</script> All Rights Reserved by <a href="https://hminnovance.com/" target="_blank">H & M Innovance LLP</a></p>
                </div>
            </div>
        </div>
   
        <div className="go-top">
            <i className="ri-arrow-up-line"></i>
        </div>
    </>
  )
}
