import React, { useCallback, useEffect, useRef, useState } from 'react';
import CallIcon from "@material-ui/icons/Call";
import Message from '../../chat/message/Message';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetCommonPage, getCurrentUser } from '../../../Page-redux/commonPageReducer/action';
import { io } from "socket.io-client";
import { API_URL } from '../../../App';
import { GetMessages, PostMessages } from '../../../Page-redux/pageMessages/action';
import { ADD_REALTIME_MESSAGE } from '../../../Page-redux/pageMessages/actionType';
import { debounce } from 'lodash';

const OtherUserMessages = () => {
  const { id } = useParams();
  let dispatch = useDispatch();
  const socket = io(`${API_URL}`);

  const [messages,setMessages] = useState("");
  const lastFetchTime = useRef(0);
  const fetchInterval = 5000; // 5 seconds, adjust as needed

  const user = useSelector((state) => state.commonPageReducer.current);
  let userPage = useSelector((item) => item.commonPageReducer.otheruserdata);
  const pageData = userPage?.find(ele=>ele);

  
  

  const chatData = useSelector((item)=>item.pageMessageReducer.msg)


  useEffect(() => {
    socket.emit("addUser", user?._id);
    socket.on("getUsers", (users) => {
    });
  }, [user]);

  useEffect(() => {
    socket.emit("userId", user?._id);
  }, [socket])


  useEffect(() => {
    if(dispatch(GetCommonPage("liked",id))){
        dispatch(GetCommonPage("liked",id))
    }
    else{
        dispatch(GetCommonPage("all",id))
    }
   
}, [id])

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);


  const handleSubmit=()=>{
    if(messages==="") return

    const msgData={
      from:user?._id,
      to:pageData?._id,
      message:messages,
      fromType:"User",
      toType:"Page"
    }
    socket.emit("sendMessage",{
      senderId:user?._id,
      receiverId:pageData?._id,
      text:messages
    })
    dispatch({
      type:ADD_REALTIME_MESSAGE,
      payload:{
        fromSelf:true,
        message:messages
      }
    })
    dispatch(PostMessages(msgData))
    setMessages("")
  }



  useEffect(()=>{
    socket.on("getMessage", ({text}) => {
      
      dispatch({
        type:ADD_REALTIME_MESSAGE,
        payload:{
          fromSelf:false,
          message:text
        }
      })
    })
  },[dispatch,socket])

  const fetchMessages = useCallback(
    debounce(() => {
      const now = Date.now();
      if (now - lastFetchTime.current >= fetchInterval) {
        if (user?._id && pageData?._id) {
          dispatch(GetMessages(user?._id,pageData?._id,'User','Page'));
          lastFetchTime.current = now;
        }
      }
    },300),
    [dispatch,user?._id,pageData?._id]
    )

    useEffect(()=>{
      fetchMessages()
    },[fetchMessages,chatData])


  return (
    <div className="h-screen flex flex-col bg-[#f2f5fa]">
      {/* Header */}
      <div className="pt-20 ">
        <div className="mx-auto w-[53%] max-sm:w-[100%]">
          <div className="bg-gray-700 flex items-center justify-between p-3">
            <div className="flex items-center gap-3">
              <img
                src={pageData?.cover?.url||""}
                className="w-10 h-10 rounded-full"
                alt="User avatar"
              />
              <p className="text-white">{pageData?.name}</p>
            </div>
            <CallIcon
              className="w-8 h-8 cursor-pointer text-white"
            />
          </div>
        </div>
      </div>

      {/* Messages Container */}
      <div className="flex-1 overflow-y-auto mx-auto w-[53%] max-sm:w-[100%] bg-gray-50">
        <div className="messages p-4">
          {/* Messages content will go here */}
          {
            chatData.map((ele)=>(
              <Message
               message={ele}
               own={ele.fromSelf}
               senderProfileImg={ele.fromSelf?user?.profile?.profileImage?.url:pageData?.cover?.url}
               receiverProfileImg={!ele.fromSelf?pageData?.cover?.url:user?.profile?.profileImage?.url}
              />
            ))
          }
         
        </div>
      </div>

      {/* Fixed Message Input */}
      <div className="border-t  border-gray-200 bg-white mx-auto w-[53%] max-sm:w-[100%] mb-1">
        <div className="md:p-4  max-sm:pt-4 max-sm:pb-4 flex items-center gap-3">
          <input
            type="text"
            className="flex-1 border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:border-blue-500"
            placeholder="Write your message..."
            value={messages}
            onChange={(e)=>setMessages(e.target.value)}
          />
          <button className="p-2 hover:bg-gray-100 rounded-full" >
            <i className="fa fa-paperclip text-gray-600" ></i>
          </button>
          <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600" onClick={handleSubmit}>
            <i className="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtherUserMessages;