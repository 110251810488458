import { Navigate } from "react-router-dom"
import { token } from "../component/home/Home"

const PrivateRoute=({children})=>{
    const user = JSON.parse(localStorage.getItem("user"));
   

    if(user.status!=="active"){
        return <Navigate to="/"/>
    }

    return (
        children
    )

}

export default PrivateRoute