import { TbSettings2 } from "react-icons/tb";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { MdChangeCircle } from "react-icons/md";
import { IoIosContact } from "react-icons/io";
import { MdAnnouncement } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { HiUsers } from "react-icons/hi2";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import { FaChartColumn } from "react-icons/fa6";
import { MdVerified } from "react-icons/md";
import "./AdminSidebar.css";
import User from "./User";
import SpamReport from "./SpamReport";
import Analysis from "./Analysis";
import ChangeRole from "./ChangeRole";
import DeleteCmtlike from "./DeleteCmtlike";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DocumentVerification from "./DocumentVerification";

const AdminSidebar = () => {
  const navigate = useNavigate()
  let [toggle, setToggle] = useState(false);
  const [showTab, setShowTab] = useState("current");

  let user = useSelector((item) => item.commonPageReducer.current);

  return (
    <div>
      <div className="left-sidebar-main">
        <div id="nav-menu">
          <nav>
            <label htmlFor="check" className="checkbtn">
              <GiHamburgerMenu
                className="icon"
                value={toggle}
                onClick={() => setToggle(true)}
              />
            </label>
          </nav>
        </div>
        <div className={`main-section ${toggle ? "show" : ""}`}>
          <aside>
            <div className="top">
              <div className="logo">
                <span>
                  {" "}
                  <TbSettings2 style={{ color: "white", fontSize: "25px" }} />
                </span>

                <h2 style={{ color: "white" }}>Dashboard</h2>
              </div>
              <div className="close" id="close-btn">
                <span value={toggle} onClick={() => setToggle(false)}>
                  <AiOutlineClose
                    style={{ color: "white", fontSize: "25px" }}
                  />
                </span>
              </div>
            </div>

            <div className="sidebar" id="myTab">
              {/* <a href="#"  ><span><TbDashboard className="sidebar-icons"/></span> 
                <h3>Dashboard</h3>   
                </a> */}

              <a
                href="#"
                className={` ${showTab == "current" ? "active" : ""}`}
                id="current-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="current"
                onClick={() => setShowTab("current")}
              >
                <span>
                  <HiUsers className="sidebar-icons" />
                </span>

                <span>
                  <h3>Users</h3>{" "}
                  <AiOutlineRight className="sidebar-icons-arrow" />
                </span>
              </a>
              <a
                href="#"
                className={showTab == "spam" ? "active" : ""}
                id="spam-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="spam"
                onClick={() => setShowTab("spam")}
              >
                <span>
                  <IoIosContact className="sidebar-icons" />
                </span>

                <span>
                  <h3>Spam report</h3>{" "}
                  <AiOutlineRight className="sidebar-icons-arrow" />
                </span>
              </a>
              <a
                href="#"
                className={showTab == "delete_cmt&like" ? "active" : ""}
                id="delete_cmt&like-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="delete_cmt&like"
                onClick={() => setShowTab("delete_cmt&like")}
              >
                <span>
                  <MdDelete className="sidebar-icons" />
                </span>
                <span>
                  <h3>Delete Comment and like </h3>{" "}
                  <AiOutlineRight className="sidebar-icons-arrow" />{" "}
                </span>
              </a>
              <a
                href="#"
                className={showTab == "change_role" ? "active" : ""}
                id="change_role-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="change_role"
                onClick={() => setShowTab("change_role")}
              >
                <span>
                  <MdChangeCircle className="sidebar-icons" />
                </span>

                <span>
                  <h3>Change role</h3>
                  <AiOutlineRight className="sidebar-icons-arrow" />
                </span>
              </a>
              <a
                href="#"
                className={showTab == "announce" ? "active" : ""}
                id="announce-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="announce"
                onClick={() => setShowTab("announce")}
              >
                <span>
                  <MdVerified className="sidebar-icons" />
                </span>
                <span>
                  <h3>Verification</h3>

                  <AiOutlineRight className="sidebar-icons-arrow" />
                </span>
              </a>
              <a
                href="#"
                className={showTab == "analysis" ? "active" : ""}
                id="analysis-tab"
                data-bs-toggle="tab"
                role="tab"
                aria-controls="analysis"
                onClick={() => setShowTab("analysis")}
              >
                <span>
                  <FaChartColumn className="sidebar-icons" />
                </span>
                <span>
                  <h3>Analysis</h3>

                  <AiOutlineRight className="sidebar-icons-arrow" />
                </span>
              </a>
              <a href="#" className="active active1 relative top-5">
                <span className="relative bottom-2">
                  <img
                    src={
                      user?.profile?.profileImage?.url
                        ? user?.profile?.profileImage?.url
                        : "https://media.istockphoto.com/id/1178152960/photo/portrait-of-a-happy-confident-young-african-american-businessman-standing-with-his-arms.webp?b=1&s=170667a&w=0&k=20&c=a2vROxAWqlvIA8vmogl_tvcZgykx8MUbTQvoqrWygw0="
                    }
                    alt="user"
                  />
                </span>
                <span>
                  <h3>
                    {user?.name?.split(" ")[0]}
                    <span className="last"></span>
                  </h3>

                  <AiOutlineRight className="sidebar-right-rotate sidebar-icons-arrow" />
                </span>
              </a>
              <div className="flex justify-center justify-items-center relative top-14">
                <button
                  onClick={() => {
                          localStorage.clear();
                          navigate("/");
                          window.location.reload();
                        }}
                  className="bg-red-700 text-white font-medium py-2 px-4 rounded hover:bg-red-700 transition duration-300 ease-in-out"
                >
                  Logout
                </button>
              </div>
            </div>
          </aside>
        </div>
      </div>
      <div className="  container-body">
        <div>
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                showTab === "current" && "show active"
              } `}
              role="tabpanel"
            >
              <User />
            </div>
            <div
              className={`tab-pane fade  ${
                showTab === "spam" && "show active"
              } `}
              role="tabpanel"
            >
              <SpamReport />
            </div>
            <div
              className={`tab-pane fade  ${
                showTab === "delete_cmt&like" && "show active"
              } `}
              role="tabpanel"
            >
              <DeleteCmtlike />
            </div>
            <div
              className={`tab-pane fade ${
                showTab === "change_role" && "show active"
              } `}
              role="tabpanel"
            >
              <ChangeRole />
            </div>
            <div
              className={`tab-pane fade ${
                showTab === "announce" && "show active"
              } `}
              role="tabpanel"
            >
              <DocumentVerification/>
            </div>
            <div
              className={`tab-pane fade ${
                showTab === "analysis" && "show active"
              } `}
              role="tabpanel"
            >
              <Analysis />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
