import "./message.css";
import { format } from "timeago.js";

export default function Message({ message, own, senderProfileImg, receiverProfileImg }) {
  console.log(senderProfileImg,receiverProfileImg);
  
  const profileImgSrc = own ? senderProfileImg?.profile?.profileImage?.url : receiverProfileImg?.profile?.profileImage?.url;
  const newProfile = own? senderProfileImg : receiverProfileImg
  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        {
          profileImgSrc &&
          (
            <img
            className="messageImg"
            src={profileImgSrc}
            alt="userImage"
          />
          )
        }
        {
         (message.message || message.fromSelf) &&
          (
            <img
             className="messageImg"
             src={newProfile}
             alt="userImage"
            />
          )
        }
        <p className="messageText">{message?.text || message.message}</p>
      </div>
      <div className="messageBottom">{format(message?.createdAt)}</div>
    </div>
  );
}
