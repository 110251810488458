import { useEffect, useState } from "react"
import AdminHeader from "./AdminHeader"
import { GetPagesByUser } from "../apiCall/GetAllFrindsList"
import { token } from "../component/home/Home"
import { API_URL, toastFunction } from "../App"

const DocumentVerification = () => {
    const [data, setData] = useState([])

    useEffect(() => {
        GetPagesByUser("all")
            .then((data) => {
                setData(data?.pages)
            })
            .catch((err) => {
                console.log(err);
            })

    }, [])
   
    const Verification=(id)=>{
        fetch(`${API_URL}/page/verify/${id}`,{
            method:"PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
        })
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            if(data.status===true){
                toastFunction(data.message)
            }
        })
        .catch((err)=>{
            toastFunction(err.message)
        })
    }


    const Decline=(id)=>{
        fetch(`${API_URL}/page/remove/doc/${id}`,{
            method:"PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
        })
        .then((res)=>{
            return res.json()
        })
        .then((data)=>{
            if(data.status===true){
                toastFunction(data.message)
            }
        })
        .catch((err)=>{
            toastFunction(err.message)
        })
    }


    return (
        <div>
            <div>
                <AdminHeader />
                <div className="card mt-5 mb-5">
                    <div className="container py-5 table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th className="text-left">Profile</th>
                                    <th className="text-left">Page Name</th>
                                    <th className="text-center">Document</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    data &&
                                    data.map((ele) => (
                                        <tr key={ele._id}>
                                            <td className="text-left">
                                                <div className="row align-items-start">
                                                    <div className="col-auto">
                                                        <img
                                                            src={ele?.cover?.url || "https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"}
                                                            alt="page_image"
                                                            className="img-fluid"
                                                            width={"70"}
                                                            style={{ borderRadius: "5px" }}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-left">{ele?.name}</td>
                                            <td className="text-center"><a href={ele?.business_license} target="_blank"><i className="fa-solid fa-eye cursor-pointer text-lg"></i></a></td>
                                            <td className="text-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-success bg-green-700"
                                                    onClick={()=>Verification(ele._id)}
                                                >
                                                    Verified
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-success bg-green-700 ml-4"
                                                    onClick={()=>Decline(ele._id)}
                                                >
                                                    Decline
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentVerification