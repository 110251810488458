import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { API_URL } from '../../../App';
import { token } from '../../home/Home';
import { useState } from 'react';
import { IoMdSend } from "react-icons/io";




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const PagePostComment = ({ comment = [], postId }) => {
    const [open, setOpen] = useState(false);
    const [comments, setComments] = useState(comment.length)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [message, setMessage] = useState();


    const CommentPost = (id) => {
        fetch(`${API_URL}/page-post/comments/${id}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ comment: message })
        })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                if (data.status == true) {

                    setComments(data.data.length)
                    handleClose()
                }
            })
            .catch((err) => {
                alert(err.message)
            })
        setMessage("")
    }



    return (
        <div>
            <Button onClick={handleOpen}>  <a ><i className="flaticon-comment"></i><span>Comment</span> <span
                className="number">{comments}</span></a></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ maxHeight: "60vh", overflow: "scroll" }} className="scrollb">

                        {comment && comment.map((item) => (
                            <div key={item?._id}>
                                <div style={{ display: "flex", justifyContent: "left", alignItems: "center" }}>
                                    <img src={item?.author?.profile?.profileImage?.url} style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "17px" }} />
                                    <div>
                                        <h5 style={{ color: "black", fontWeight: "bold" }}>{item?.author?.name}</h5>
                                    </div>
                                </div>
                                <div style={{ width: "85%", marginLeft: "50px" }}>
                                    <div style={{ textAlign: "left", textTransform: "capitalize", fontSize: "13px" }}>
                                        {item?.comment}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <br />
                    <div className="d-flex scrollb" style={{ justifyContent: "center", alignItems: "center", margin: "auto" }}>
                        <textarea style={{ width: "90%", borderRadius: "20px", border: "2px solid grey", overflowY: "hidden", textAlign: "center" }} value={message} onChange={(e) => setMessage(e.target.value)} />
                        <div style={{ width: "10%", borderRadius: "20px", alignItems: "center", cursor: "pointer" }} >
                            {/* <img src="assets/images/message_arrow.png" onClick={() => CommentPost(postId)} /> */}
                            {" "} <IoMdSend style={{ fontSize: "25px" }} onClick={() => CommentPost(postId)} />
                        </div>
                    </div>

                </Box>
            </Modal>
        </div>
    )
}

export default PagePostComment