import React, { useRef, useState } from "react";
import { API_URL } from "../../../App";
import { token } from "../Home";
import "./upload.css";
import CameraCapture from "./CameraCapture";

export default function Upload() {
  const inputRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [description, setDescription] = useState("");
  const [showPhotoOptions, setShowPhotoOptions] = useState(false);
  const [showCameraCapture, setShowCameraCapture] = useState(false);

  const handleClickUpload = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    setImageFile(e.target.files[0]);
    setShowPhotoOptions(false); // Hide options when a file is selected
  };

  const handleVideoChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleCameraCapture = (file) => {
    setImageFile(file);
    setShowCameraCapture(false); // Hide the CameraCapture component
    setShowPhotoOptions(false); // Hide photo options
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fdata = new FormData();
    fdata.set("image", imageFile);
    fdata.set("description", description);

    fetch(`${API_URL}/post/image`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          window.location.reload();
        } else {
          alert(data.message);
        }
      })
      .catch((err) => alert(err.message));
  };

  const handleVideoSubmit = (e) => {
    e.preventDefault();
    let fdata = new FormData();
    fdata.set("video", videoFile);
    fdata.set("description", description);

    fetch(`${API_URL}/video/upload`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: fdata,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          window.location.reload();
        } else {
          alert(data.message);
        }
      })
      .catch((err) => alert(err.message));
  };

  return (
    <div className="news-feed news-feed-form">
      <h3 className="news-feed-title">Create New Post</h3>

      <form>
        <div className="form-group">
          <textarea
            name="message"
            className="form-control"
            placeholder="Write something here..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        {imageFile && (
          <div className="shareImgContainer">
            <img
              className="shareImg"
              src={URL.createObjectURL(imageFile)}
              alt=""
            />
            <br />
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-warning"
                style={{ margin: "10px" }}
                onClick={() => setImageFile(null)}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary"
                style={{ margin: "10px" }}
                onClick={handleSubmit}
              >
                POST
              </button>
            </div>
          </div>
        )}
        {videoFile && (
          <div className="shareImgContainer">
            <video controls autoplay>
              <source src={URL.createObjectURL(videoFile)} type="video/mp4" />
            </video>
            <br />
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-warning"
                style={{ margin: "10px" }}
                onClick={() => setVideoFile(null)}
              >
                CANCEL
              </button>
              <button
                className="btn btn-primary"
                style={{ margin: "10px" }}
                onClick={handleVideoSubmit}
              >
                POST
              </button>
            </div>
          </div>
        )}

        {!showCameraCapture && (
          <ul className="button-group d-flex justify-content-between align-items-center">
            <input
              style={{ display: "none" }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
              accept="image/png, image/jpeg"
            /> 
            <li className="photo-btn">
              <button
                type="button"
                onClick={() => setShowPhotoOptions(!showPhotoOptions)}
              >
                <i className="flaticon-gallery"></i> Photo
              </button>
              {showPhotoOptions && (
                <div className="photo-options-box">
                  <button type="button" onClick={handleClickUpload}>
                    Upload from Computer
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowCameraCapture(true)}
                  >
                    Use Camera
                  </button>
                </div>
              )}
            </li>
            <li className="video-btn">
              <input
                style={{ display: "none" }}
                type="file"
                accept="video/mp4"
                onChange={handleVideoChange}
              />
              <button type="button" onClick={() => inputRef.current.click()}>
                <i className="flaticon-video"></i> Video
              </button>
            </li>
            <li className="tag-btn">
              <button type="button">
                <i className="flaticon-tag"></i> Tag Friends
              </button>
            </li>
          </ul>
        )}
      </form>

      {showCameraCapture && <CameraCapture onPost={handleCameraCapture} />}
    </div>
  );
}
