import { useEffect, useState } from "react"
import { GetPagesByUser } from "../../apiCall/GetAllFrindsList"
import axios from "axios";
import { token } from "../home/Home";
import { API_URL, toastFunction } from "../../App";
import { useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';



const Settings = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [current, setCurrent] = useState(false);
    const [data, setData] = useState([]);
    const [val, setVal] = useState("");

    const [users, setUsers] = useState([]);
    const user = useSelector((state) => state.commonPageReducer.current);

    console.log(user, "jhguy");



    useEffect(() => {
        GetPagesByUser("managed")
            .then((data) => {
                setData(data?.pages);
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])

    const handleChange = (e, id) => {
        if (e.target.checked && id) {
            setTimeout(() => {
                setModalOpen(true)
            }, 500)
        }
    }

    const SendPageInvite = (email, pageId) => {
        let obj = { email: email, pageId: pageId }
        axios.post(`${API_URL}/page/invitation/send`, obj, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
            .then((res) => {
                if (res.data.status === true) {
                    toastFunction(res.data.message)
                }
            })
            .catch((err) => {

            })
    }

    const allUsers = () => {
        axios.get(`${API_URL}/users?name=${val}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
            .then((res) => {
                setUsers(res?.data?.users)
            })
            .catch((err) => {

            })
        setVal("")
    }


    const RemoveManager=(pageId,managerId)=>{
        let obj ={}
        axios.put(`${API_URL}/page/invitation/remove/${pageId}/${managerId}`,obj, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
            .then((res) => {
                if (res.data.status === true) {
                    toastFunction(res.data.message)
                   setTimeout(()=>{
                    window.location.reload()
                   },500)
                }
            })
            .catch((err) => {

            })
    }



    return (
        <div className="col-lg-6 col-md-6" style={{ margin: "auto", marginTop: "90px" }}>

            <div>
                <ul>
                    <li style={{ background: "rgb(165 217 255 / 39%)" }}>
                        <div className="flex justify-between bg-[#dbf0ff] p-1 rounded-sm" >
                            <span className="font-medium"><i className="fa-solid fa-key mr-2 text-base"></i>Page access</span>
                            {
                                current ? <i className="fa-solid fa-angle-down text-lg cursor-pointer" onClick={() => setCurrent(!current)}></i> : <i className="fa-solid fa-angle-right text-lg cursor-pointer" onClick={() => setCurrent(!current)}></i>
                            }

                        </div>
                        {
                            current && (
                                <div className="mt-2">
                                    {
                                        data?.map((ele) => {
                                            return user?._id == ele?.page_admin_id?._id && (
                                                <div>
                                                    <div className="card mb-3 " style={{ padding: "10px" }} key={ele._id}>
                                                        <div className="flex gap-5">
                                                            <div>
                                                                {ele.cover && ele.cover.url ? (
                                                                    <img src={ele.cover.url} alt="img" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                                                ) : (
                                                                    <img src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892" alt="img" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                                                                )}
                                                            </div>

                                                            <div className="flex lg:gap-32 max-sm:gap-12 md:gap-16">
                                                                <span style={{ fontSize: "17px" }}>{ele.name}</span>
                                                                <div className="mt-1">
                                                                    <input type="radio" className="w-full h-5" onChange={(e) => handleChange(e, ele._id)} />
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    {
                                                        ele?.managers?.length > 0 && (
                                                            <div>
                                                                <p className=" mt-4 mb-4 m-3 text-black text-base font-semibold">People with Page access</p>
                                                                {
                                                                    ele?.managers?.map((item) => (
                                                                        <div className="card mb-3 bg-[#f0f8ff]" style={{ padding: "8px" }} >
                                                                            <div className="flex gap-4">
                                                                                {item?.profile?.profileImage?.url ? (
                                                                                    <img src={item?.profile?.profileImage?.url} alt="img" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                                                ) : (
                                                                                    <img src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892" alt="img" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                                                )}

                                                                                <div className="flex md:gap-60 max-sm:gap-24">
                                                                                    <div className="flex justify-center items-center md:w-16 max-sm:w-14">
                                                                                        <span style={{ fontSize: "16px" }}>{item?.name}</span>
                                                                                    </div>

                                                                                    <div className="flex justify-center items-center">
                                                                                        <Dropdown>
                                                                                        <Dropdown.Toggle id="dropdown-basic" style={{ border: "none", fontSize:"25px",background:"#64c9c2" ,height:"40px"}}>
                                                                                        </Dropdown.Toggle>
                                                                                        <Dropdown.Menu>
                                                                                            <Dropdown.Item href="#/action-2" onClick={()=>RemoveManager(ele?._id,item?._id)}>Remove</Dropdown.Item>
                                                                                        </Dropdown.Menu>
                                                                                    </Dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                        ))
                                                                }
                                                </div>
                                            )
                                        }


                                                    {/* -----modal----- */ }
                                                    {
                                                modalOpen && (
                                                <div
                                                    id="static-modal"
                                                    data-modal-backdrop="static"
                                                    tabIndex="-1"
                                                    aria-hidden="true"
                                                    className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  max-md:flex max-lg:flex  justify-center items-center w-full md:inset-0 h-full max-h-full  bg-gray-800 bg-opacity-50"
                                                >
                                                    <div className="relative top-10 p-4 w-full max-w-2xl max-h-full">
                                                        {/* Modal content */}
                                                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700  lg:left-[360px] md:left-14">
                                                            {/* Modal header */}
                                                            <div className="flex items-center justify-between p-3 md:p-5 border-b rounded-t dark:border-gray-600">
                                                                <div className="flex gap-1">

                                                                    <div >
                                                                        <h3 className="text-[19px] font-[500] text-gray-900 dark:text-gray-900">

                                                                        </h3>
                                                                        <p className="text-gray-700 m-0">Send page invitation, search by name here.</p>
                                                                    </div>

                                                                </div>

                                                                <button
                                                                    type="button"
                                                                    className="text-gray-900 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-gray-600"
                                                                    onClick={() => setModalOpen(false)}
                                                                >
                                                                    <svg
                                                                        className="w-3 h-3"
                                                                        aria-hidden="true"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 14 14"
                                                                    >
                                                                        <path
                                                                            stroke="currentColor"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            strokeWidth="2"
                                                                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                                                        />
                                                                    </svg>
                                                                    <span className="sr-only">Close modal</span>
                                                                </button>
                                                            </div>
                                                            {/* Modal body */}
                                                            <div className="p-2">
                                                                <input placeholder="eg: tom" className="max-sm:w-[85%] w-[450px] p-2 border-[1.5px] border-gray-600 rounded-md"
                                                                    value={val}
                                                                    onChange={(e) => setVal(e.target.value)}
                                                                />
                                                                <button className="w-10 h-11 bg-green-600 rounded ml-1 hover:bg-green-400" onClick={() => allUsers()}><i className="fa-solid fa-magnifying-glass text-white"></i></button>
                                                            </div>
                                                            <div className="h-[390px] overflow-y-auto">
                                                                <div className="w-[95%]  mx-auto rounded-md border-gray-400 space-y-4 mt-4 mb-4">
                                                                    {
                                                                        users.length > 0 && (
                                                                            users.map((item) => (
                                                                                <div className="card mb-3 bg-[#f0f8ff]" style={{ padding: "8px" }} >
                                                                                    <div className="flex gap-4">
                                                                                        {item?.profile?.profileImage?.url ? (
                                                                                            <img src={item?.profile?.profileImage?.url} alt="img" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                                                        ) : (
                                                                                            <img src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892" alt="img" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                                                                                        )}

                                                                                        <div className="flex md:gap-60 max-sm:gap-24">
                                                                                            <div className="flex justify-center items-center md:w-16 max-sm:w-14">
                                                                                                <span style={{ fontSize: "16px" }}>{item?.name}</span>
                                                                                            </div>

                                                                                            <div className="flex justify-center items-center">
                                                                                                <button className="w-16 h-9 bg-blue-600 rounded  hover:bg-blue-500 text-white" onClick={() => SendPageInvite(item?.email, ele?._id)}>Send</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        )
                                                                    }

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                                    }
                                </div>
                            )
                        })
                                    }
                    </div>
                    )
                        }
                </li>
            </ul>
        </div>
        </div >
    )
}

export default Settings