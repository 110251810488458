import { useCallback, useEffect, useRef, useState } from "react";
import CallIcon from "@material-ui/icons/Call";
import { useDispatch, useSelector } from "react-redux";
import { GetMessages, GetUsers, PostMessages } from "../../../Page-redux/pageMessages/action";
import { getCurrentUser } from "../../../Page-redux/commonPageReducer/action";
import { useParams } from "react-router-dom";
import { GetUserPage } from "../../../Page-redux/userPageReducer/action";
import axios from "axios";
import { API_URL } from "../../../App";
import { token } from "../../home/Home";
import { io } from "socket.io-client";
import { ADD_REALTIME_MESSAGE } from "../../../Page-redux/pageMessages/actionType";
import Message from "../../chat/message/Message";
import { format } from "timeago.js";
import {debounce} from "lodash"


const PageMessages = () => {

  const socket = io(`${API_URL}`);

  const [messages, setMessages] = useState("");
  const lastFetchTime = useRef(0);
  const fetchInterval = 5000; // 5 seconds, adjust as needed
  
  const [currentChat, setCurrentChat] = useState(false);
  const [data, setData] = useState({})
  let dispatch = useDispatch()
  const { id } = useParams()
  const user = useSelector((state) => state.commonPageReducer.current);
  let userPage = useSelector((item) => item.userPageReducer.data);
  const pageData = userPage?.find(ele => ele);

  const chatData = useSelector((item) => item.pageMessageReducer.msg);
  const scrollRef = useRef();

  let pageMessageUsers = useSelector((item) => item.pageMessageReducer.Users);

  useEffect(() => {
    socket.emit("addUser", pageData?._id);
    socket.on("getUsers", (users) => {
    });
  }, [user]);

  useEffect(() => {
    setTimeout(()=>{
      socket.emit("userId", pageData?._id);
    },500)
  }, [socket])


  useEffect(() => {
    dispatch(GetUsers(id))
  }, [dispatch,id,data?._id])

  useEffect(() => {
    dispatch(GetUserPage(id))
  }, [id])

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatData]);

  const SingleDetails = (Id) => {
    setCurrentChat(true)
    axios.get(`${API_URL}/page/single/details/${Id}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })
      .then((res) => {
        setData(res.data.data)
      })
      .catch((err) => {

      })
      dispatch(GetUsers(id))
  }


  const handleSubmit = () => {
    if (messages === "") return

    const msgData = {
      from: pageData?._id,
      to: data?._id,
      message: messages,
      fromType: "Page",
      toType: "User"
    }
    socket.emit("sendMessage", {
      senderId: pageData?._id,
      receiverId: data?._id,
      text: messages
    })
    dispatch({
      type: ADD_REALTIME_MESSAGE,
      payload: {
        fromSelf: true,
        message: messages
      }
    })
    dispatch(PostMessages(msgData))
    setMessages("")
  }

  const fetchMessages = useCallback(
    debounce(() => {
      const now = Date.now();
      if (now - lastFetchTime.current >= fetchInterval) {
        if (data?._id && pageData?._id) {
          dispatch(GetMessages(pageData?._id, data?._id, 'Page', 'User'));
          lastFetchTime.current = now;
        }
      }
    },300),
    [dispatch, data?._id, pageData?._id]
    )

    useEffect(()=>{
      fetchMessages()
    },[fetchMessages,chatData])


  useEffect(() => {
    socket.on("getMessage", ({text}) => {
      
      dispatch({
        type: ADD_REALTIME_MESSAGE,
        payload: {
          fromSelf: false,
          message: text
        }
      })
    })
  }, [dispatch,socket])


  return (
    <div className="main-content-wrapper d-flex flex-column">
      {/* <Heading /> */}

      <div className="chatsMainContainer">
        <div id="frame">
          <div id="sidepanel">
            <div id="contacts">
              {
                pageMessageUsers.map((ele) => (
                  <ul onClick={() => SingleDetails(ele?.user?._id)} className="bg-[#32506c]">
                    <li className="contact">
                      <div className="wrap">
                        <span className="contact-status online"></span>
                        <img
                          className="conversationImg"
                          src={
                            ele?.user?.profile_pic?.profileImage?.url
                          }
                          alt="image_error"
                        />
                        {
                          ele.unreadCount>0 && (
                            <div className="md:hidden">
                            <p className="text-[#f17575] font-bold absolute right-0">{ele?.unreadCount}</p>
                            </div>
                          )
                        }
                       
                        <div className="meta flex">
                          <p className="name text-gray-200">{ele?.user?.name}</p>
                          {/* <p class="preview">You just got LITT up, Mike.</p> */}
                          {
                            ele.unreadCount>0 && (
                              <div>
                                  <p className="text-sm text-[#f17575] font-[600]">{ele.unreadCount>0 && `${ele.unreadCount} new messages`}</p>
                                  <p className="text-sm">{format(ele.lastMessageDate)}</p>
                              </div> 
                            )
                          }
                          
                        </div>
                      </div>
                    </li>
                  </ul>
                ))
              }


            </div>
          </div>
          {currentChat ? (
            <div className="content">
              <div className="contact-profile" style={{ display: "flex" }}>
                <div style={{ flex: 5 }}>
                  {" "}
                  <img
                    src={
                      data?.profile?.profileImage?.url
                    }
                    className="conversationImg"
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "12px",
                    }}
                    alt="image error"
                  />
                  <p
                    style={{
                      marginTop: "10px",
                    }}
                    className="text-gray-800"
                  >
                    {data?.name}
                  </p>
                </div>
                <div
                  class="social-media"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flex: 1,
                  }}
                >

                  <CallIcon
                    style={{
                      width: "30px",
                      height: "30px",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
              <div className="messages">
                {
                  chatData.map((ele) => (
                    <div ref={scrollRef}>
                    <Message
                      message={ele}
                      own={ele.fromSelf}
                      senderProfileImg={ele.fromSelf ? pageData?.cover?.url: data?.profile?.profileImage?.url }
                      receiverProfileImg={ ele.fromSelf ? pageData?.cover?.url: data?.profile?.profileImage?.url }
                    />
                    </div>
                  ))
                }
              </div>
              <div className="message-input" >
                <div className="wrap ">
                  <input
                    type="text"
                    placeholder="Write your message..."
                    value={messages}
                    onChange={(e) => setMessages(e.target.value)}
                    className="mb-2"
                  />
                  <i
                    className="fa fa-paperclip attachment"
                    aria-hidden="true"
                  ></i>
                  <button className="submit" onClick={handleSubmit}>
                    <i className="fa fa-paper-plane" aria-hidden="true" ></i>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <span className="noConversationText">Let's talk</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default PageMessages