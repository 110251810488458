import React from 'react'
import Profile from './rightside/Profile'
import Like from './rightside/Like'
import { token } from './Home'

export default function RightSide() {

  return (
    <>
      <div className="col-lg-3 col-md-12">
        <aside className="widget-area">
          {token ? <Profile /> : ""}
          <Like />
        </aside>
      </div>
    </>
  )
}
