import React, { useEffect, useState } from "react";
import { API_URL } from "../../App";
import { token } from "../home/Home";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { addFriend } from "../../apiCall/GetAllFrindsList";

export default function FriendRequest() {
  const [showRequest, setShowRequest] = useState("show active");
  const [showFriendSugestion, setShowFriendSugestion] = useState("");
  const [showRequestactive, setShowRequestactive] = useState("active");
  const [showFriendSugestionactive, setShowFriendSugestionactive] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const friendRequest = () => {
    setShowRequest("show active");
    setShowFriendSugestion("");
    setShowRequestactive("active");
    setShowFriendSugestionactive("");
  };

  const friends = () => {
    setShowRequest("");
    setShowFriendSugestion("show active");
    setShowRequestactive("");
    setShowFriendSugestionactive("active");
  };

  const [friendSugestion, setFriendSugestion] = useState([]);
  const [friendRequestList, setFriendRequestList] = useState([]);

  const getSugestion = () => {
    fetch(`${API_URL}/users/sugestion`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setFriendSugestion(data?.message);
      })
      .catch((error) => {
        console.error("Unable to get friends", error);
      });
  };

  const getFriendRequest = () => {
    fetch(`${API_URL}/get/all/friend/request`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setFriendRequestList(data.message);
      });
  };

  useEffect(() => {
    getSugestion();
  }, []);

  useEffect(() => {
    getFriendRequest();
  }, []);

  const AddFriend = (id) => {
    addFriend(id)
      .then(() => {
        getSugestion();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const acceptFriendRequest = (id) => {
    fetch(`${API_URL}/accept/friend/request/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          toast("Request Accepted", {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
        getFriendRequest();
        getSugestion();
      });
  };

  const RejectRequest = (id) => {
    fetch(`${API_URL}/Reject/request/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {})
      .catch((err) => {
        alert(err);
      });
  };

  // Filter friends based on search query
  const filteredFriendRequestList = friendRequestList.filter((friend) =>
    friend?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredFriendSugestion = friendSugestion.filter((friend) =>
    friend?.name?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="main-content-wrapper d-flex flex-column">
        <div className="content-page-box-area">
          <div className="page-banner-box bg-4">
            <h3>Friend Requests</h3>
          </div>
          <div className="friends-inner-box-style d-flex justify-content-between align-items-center margin-top-25">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li
                className="nav-item"
                onClick={() => friendRequest()}
                style={{ cursor: "pointer" }}
              >
                <span
                  className={`nav-link ${showRequestactive}`}
                  id="friend-requests-tab"
                  data-bs-toggle="tab"
                  role="tab"
                  aria-controls="friend-requests"
                >
                  Friend Requests
                </span>
              </li>
              <li
                className="nav-item"
                onClick={() => friends()}
                style={{ cursor: "pointer" }}
              >
                <span
                  className={`nav-link ${showFriendSugestionactive}`}
                  id="people-you-know-tab"
                  data-bs-toggle="tab"
                  role="tab"
                  aria-controls="people-you-know"
                >
                  People you may know
                </span>
              </li>
            </ul>
            <div className="friends-search-box">
              <form onSubmit={(e) => e.preventDefault()}>
                <input
                  type="text"
                  className="input-search"
                  placeholder="Search friends..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button type="submit">
                  <i className="ri-search-line"></i>
                </button>
              </form>
            </div>
          </div>

          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${showRequest} `}
              id="friend-requests"
              role="tabpanel"
            >
              <div className="row justify-content-center">
                {filteredFriendRequestList.length > 0 ? (
                  filteredFriendRequestList.map((item, index) => (
                    <div key={item?._id} className="col-lg-3 col-sm-6">
                      <div className="single-friends-card">
                        <div className="friends-image">
                          <img
                            src={`assets/images/friends/friends-bg-${index + 1}.jpg`}
                            alt="img"
                          />
                          <Link to={`/user/profile/${item?._id}`}>
                            <div className="icon">
                              <i className="flaticon-user"></i>
                            </div>
                          </Link>
                        </div>
                        <div className="friends-content">
                          <div className="friends-info d-flex justify-content-between align-items-center">
                            <span>
                              <img
                                src={`${item?.profile?.profileImage?.url}`}
                                alt="img"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </span>
                            <div className="text ms-3">
                              <h3>{item?.name}</h3>
                            </div>
                          </div>
                          <div className="button-group d-flex justify-content-between align-items-center">
                            <div className="add-friend-btn">
                              <button
                                type="submit"
                                onClick={() => acceptFriendRequest(item?._id)}
                              >
                                Accept Request
                              </button>
                            </div>
                            <div className="send-message-btn">
                              <button
                                type="submit"
                                style={{ color: "red" }}
                                onClick={() => RejectRequest(item?._id)}
                              >
                                Reject Request
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>You do not have any request</div>
                )}
              </div>

              <div className="load-more-posts-btn">
                <span>
                  <i className="flaticon-loading"></i> Load More
                </span>
              </div>
            </div>

            <div
              className={`tab-pane fade ${showFriendSugestion} `}
              id="people-you-know"
              role="tabpanel"
            >
              <div className="row justify-content-center">
                {filteredFriendSugestion.length > 0 ? (
                  filteredFriendSugestion.map((item, index) => (
                    <div className="col-lg-3 col-sm-6" key={index}>
                      <div className="single-friends-card">
                        <div className="friends-image">
                          <img
                            src={`assets/images/friends/friends-bg-${index + 1}.jpg`}
                            alt="img"
                          />
                          <Link to={`/user/profile/${item?._id}`}>
                            <div className="icon">
                              <span>
                                <i className="flaticon-user"></i>
                              </span>
                            </div>
                          </Link>
                        </div>
                        <div className="friends-content">
                          <div className="friends-info d-flex justify-content-between align-items-center">
                            <img
                              src={`${item?.profile?.profileImage?.url}`}
                              alt="img"
                              style={{ width: "100px", height: "100px" }}
                            />
                            <div className="text ms-3">
                              <h3>{item?.name}</h3>
                              <span>{item?.friends?.length} Friends</span>
                            </div>
                          </div>
                          <div className="button-group d-flex justify-content-between align-items-center">
                            <div className="add-friend-btn">
                              <button
                                type="submit"
                                onClick={() => {
                                  AddFriend(item?._id);
                                }}
                              >
                                Add Friend
                              </button>
                            </div>
                            <div className="send-message-btn">
                              <button type="submit">Send Message</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>NO Data Found</div>
                )}
              </div>
              <div className="load-more-posts-btn">
                <span>
                  <i className="flaticon-loading"></i> Load More
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
