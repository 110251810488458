import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { API_URL } from '../../../App';
import { token } from '../../home/Home';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PagePostLike = ({ like, postId }) => {
  const [open, setOpen] = React.useState(false);
  const [likes, setLikes] = React.useState(like.length);
  const [isLiked, setIsLiked] = React.useState(!!like.length); // Track like state (true if liked, false otherwise)

  console.log(isLiked,"dewfwefrwf")
 

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const toggleLike = async (id) => {
    const url = isLiked ? `${API_URL}/page-post/dislike/${id}` : `${API_URL}/page-post/likes/${id}`;
    const method = isLiked ? 'PUT' : 'PUT'; // Adjust method based on like state

    try {
      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();

      if (data.status === true) {
       
        setLikes(data.likes.length);
        setIsLiked(!isLiked); // Update like state based on response
      }
    } catch (error) {
     alert('Error liking/unliking post:', error);
    }
  };

  return (
    <div>
      <Button onClick={() => toggleLike(postId)}>
      <i className="flaticon-like" style={{ color: isLiked ? 'blue' : 'black' }}></i><span>Like</span>
        <span onClick={handleOpen} className='number'>{likes}</span>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {like && like.map((item) => (
            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }} key={item?._id}>
              <img src={item?._id?.profile?.profileImage?.url} style={{ width: "40px", height: "40px", borderRadius: "50%", marginRight: "17px" }} alt="img"/>
              <div>
              <h5 style={{ color: "black", fontWeight: "bold" }}>{item?._id?.name}</h5>
              </div>
            </div>
          ))}
        </Box>
      </Modal>
    </div>
  );
};

export default PagePostLike;
