import { useSelector } from "react-redux";

const OtherUserPageAbout = () => {

    let userPage = useSelector((item) => item.commonPageReducer.otheruserdata);
    const pageData = userPage?.find(page => page);



    return (
        <div className="row">
            <div className="col-lg-5 col-md-12">
                <div className="about-personal-information">
                    <div className="about-header d-flex justify-content-between align-items-center">
                        <div className="title">Business Information</div>

                    </div>

                    <ul className="information-list">
                        <li>
                            <span className="text-base text-gray-800 font-medium">Category:</span>{" "}
                            {pageData?.category || "NA"}
                        </li>
                        <li>
                            <span className="text-base text-gray-800 font-medium">Email:</span>{" "}
                            <a href={`mailto:${pageData?.email}`}>
                                <span
                                    className="__cf_email__"
                                    style={{ color: "gray", fontWeight: 400 }}
                                >
                                    {pageData?.email || "NA"}
                                </span>
                            </a>
                        </li>
                        <li>
                            <span className="text-base text-gray-800 font-medium">Website URL:</span>{" "}
                            {pageData?.website_url || "NA"}
                        </li>
                        <li>
                            <span className="text-base text-gray-800 font-medium">Phone:</span>{" "}
                            <a href={`tel:${pageData?.mobile}`}>{pageData?.mobile || "NA"}</a>
                        </li>
                        <li>
                            <span className="text-base text-gray-800 font-medium">Address:</span>{" "}
                            {pageData?.address || "NA"}
                        </li>
                        <li className="md:flex md:space-x-3">
                            <span className="text-base text-gray-800 font-medium">Business hours:</span>{" "}
                            <ul className="max-sm:mt-5">
                                {["friday", "saturday", "sunday", "monday", "tuesday", "wednesday", "thursday"].map((day) => (
                                    <li key={day} className="flex items-center space-x-4">
                                        <p className="text-base text-gray-800 font-medium w-24">
                                            {day.charAt(0).toUpperCase() + day.slice(1)}
                                        </p>
                                        <p className="text-base text-gray-600 font-medium">
                                            {pageData?.business_hours?.[day]?.isOpen
                                                ? `${pageData.business_hours[day].open} - ${pageData.business_hours[day].close}`
                                                : "Close"}
                                        </p>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-7 col-md-12">
                <div className="about-details-information">
                    <div className="information-box-content">
                        <div className="information-header d-flex justify-content-between align-items-center">
                            <div className="title">Description</div>
                        </div>
                        <div className="content">
                            <p>{pageData?.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtherUserPageAbout