import { useEffect, useState } from "react"
import { GetPagesByUser } from "../../apiCall/GetAllFrindsList"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentUser } from "../../Page-redux/commonPageReducer/action"

const LikedPages=()=>{
    const [data,setData]=useState([])
    const user = useSelector((state) => state.commonPageReducer.current);
    let dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCurrentUser());
      }, []);


    useEffect(()=>{ 
        GetPagesByUser("liked")
        .then((data)=>{
                setData(data?.pages)
                localStorage.setItem("count",data?.pages[0].likes.length)
                localStorage.setItem("pageLikes",JSON.stringify(data?.pages))
        })
        .catch((err)=>{
            console.log(err);
        })
    },[])

    return (
        <div className="col-lg-6 col-md-6" style={{margin:"auto",marginTop:"90px"}}>
                            <h2 style={{fontSize:"var(--font-size)",fontWeight:"600"}} className="mb-3"></h2>
            <div className="my_pages ">
            {
                data?.map((ele)=>(
                    <div className="card mb-3 " style={{padding:"10px"}} key={ele._id}>
                        <div className="flex gap-5">
                        <Link to={user?._id===ele?.page_admin_id?._id?`/user/page/${ele._id}`:`/page/${ele?._id}`}>
                        <div>
                        {ele.cover && ele.cover.url ? (
                        <img src={ele.cover.url} alt="img" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                        ) : (
                         <img src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1709533220~exp=1709533820~hmac=47c52625a3fedd075b20fafcd8ba89b1bd35b9c720edc01c79db142d416e1892" alt="img" style={{ width: "80px", height: "80px", borderRadius: "50%" }} />
                        )}
                        </div>
                        </Link>  
                        <div >
                        <span style={{fontSize:"17px"}}>{ele.name}</span>
                        </div>
                        
                        
                        </div>
                    </div>
                ))
            }
               
            </div>
        </div>
    )
}

export default LikedPages