import { useState } from "react"
import "./Page.css"
import CreatePage from "./CreatePage"
import YourPage from "./YourPage"
import Heading from '../heading/heading'
import Footer from "../common/Footer"
import LikedPages from "./LikedPages"
import Settings from "./Settings"

const Page=()=>{
const [showTab,setShowTab]=useState("current")


return (
  <div >
       {/* <Heading /> */}
  
    <div className="row md:w-full mx-auto">
     
        <div className="col-lg-3 col-md-6 mt-5" >
                <div className="create-pages mt-5">
                <div className="title" style={{fontWeight:"600",marginBottom:"15px"}}>Pages</div>
                <ul className="information-list" id="myTab">
                  <li>
                    <a  className={showTab=="current"?"active":""} id="current-tab" data-bs-toggle="tab" role="tab" aria-controls="current" onClick={()=>setShowTab("current")}>
                    <span><i className="fa-solid fa-flag mr-2"></i>{" "}Your pages</span>
                    </a>
                   
                  </li>
              <li>
               
                <a  className={showTab=="create"?"active":""} id="create-tab" data-bs-toggle="tab" role="tab" aria-controls="create" onClick={()=>setShowTab("create")}>
                <span><i className="fa-solid fa-plus mr-2"></i>{" "}Create New Page</span>{" "}
                </a>
              </li>
              <li>
                <a className={showTab=="likedpage"?"active":""} id="likedpage-tab" data-bs-toggle="tab" role="tab" aria-controls="likedpage" onClick={()=>setShowTab("likedpage")}>
                <span><i className="fa-solid fa-thumbs-up mr-2"></i>{" "}Liked Pages</span>
                </a>
               
              </li>
              <li>
                <a className={showTab=="invitations"?"active":""} id="invitations-tab" data-bs-toggle="tab" role="tab" aria-controls="invitations" onClick={()=>setShowTab("invitations")}> 
                  <span><i className="fa-solid fa-user-plus mr-2"></i>{" "}Invitations</span>{" "}
                  </a>
              </li>
              <li>
                <a className={showTab=="settings"?"active":""} id="settings-tab" data-bs-toggle="tab" role="tab" aria-controls="settings" onClick={()=>setShowTab("settings")}> 
                  <span><i className="fa-solid fa-gear mr-2"></i>{" "}Settings</span>{" "}
                  </a>
              </li>
            
            </ul>
                </div>
        </div>
        <div className="col-lg-9 col-md-12">
            <div className="all_section" >
              <div className="tab-content" id="myTabContent">
              <div className={`tab-pane fade ${showTab == "current" && "show active"} `} role="tabpanel" >

              <YourPage/>
             </div>
             <div className={`tab-pane fade  ${showTab == "create" && "show active"} `} role="tabpanel">
             <CreatePage/>
             </div>
             <div className={`tab-pane fade  ${showTab == "likedpage" && "show active"} `} role="tabpanel">
                <LikedPages/>
             </div>
             <div className={`tab-pane fade ${showTab == "invitations" && "show active"} `} role="tabpanel">
                  invitations
             </div>
             <div className={`tab-pane fade ${showTab == "settings" && "show active"} `} role="tabpanel">
                  <Settings/>
             </div>
              </div>
             
            </div>
        </div>
    </div>
    <Footer/>
    </div>
)
}

export default Page