import { useDispatch, useSelector } from "react-redux";
import Login from "../component/auth/Login"
import Home, { token } from "../component/home/Home"
import { getCurrentUser } from "../Page-redux/commonPageReducer/action";
import { useEffect } from "react";
import PrivateRoute from "./PrivateRoute";


const ConditionalPage = () => {

    const user = JSON.parse(localStorage.getItem("user"));
    return (
        <div>
            {
                user?.status=="active"  ? <PrivateRoute><Home /></PrivateRoute> : <Login /> 
            }
        </div>
    )
}

export default ConditionalPage