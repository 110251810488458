import { useEffect, useState } from "react";
import { GetPagesByUser } from "../../../apiCall/GetAllFrindsList";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {GetCommonPage, getCurrentUser } from "../../../Page-redux/commonPageReducer/action";
import { GetUserPage } from "../../../Page-redux/userPageReducer/action";


const PageRight = () => {

    const dispatch = useDispatch();
    
    // Redux selectors
    const user = useSelector((state) => state.commonPageReducer.current);
    const alluserPageData = useSelector((state) => state.commonPageReducer.otheruserdata);
    const userPageData = useSelector((state) => state.userPageReducer.data);

    let reviewsData = useSelector((item) => item.commonPageReducer.reviews)


  
    useEffect(() => {
      dispatch(getCurrentUser());
    }, [dispatch]);
  
    
    const getDescription = () => {
   
      const isAdmin = userPageData?.find(page => 
        page.page_admin_id?._id === user?._id
      );
  
      if (isAdmin) {
        const userPage = userPageData?.find(page => 
          page.page_admin_id?._id === user?._id
        );
        return userPage?.description || '';
      }
  
      const otherUserPage = alluserPageData?.find(page => page?.description);
      return otherUserPage?.description || '';
    };
  
    // Check if user is page admin
    
  
    // Get admin data if user is admin
    const getCategory = () => {
      const isPageAdmin = userPageData?.some(page => 
        page.page_admin_id?._id === user?._id
      );
      if(isPageAdmin){
        const userData = userPageData?.find(page=>page)
        return userData.category
      }
      const otherUserData = alluserPageData?.find(page=>page);
      return otherUserData?.category || null;
    };
  
  
    return (
      <div className="col-lg-3 col-md-12">
        <aside className="widget-area bg-white">
          <div className="p-6">
            <h4 className="pb-3 font-semibold">
              Intro
            </h4>
            <p className="text-center pb-2 text-black border-b border-gray-400">
              {getDescription()}
            </p>
          </div>
  
          <div className="intro_section">
            <ul className="p-6 space-y-3">
              <li>
                <i className="fa-brands fa-product-hunt" />{" "}
                {getCategory() || "NA"}
              </li>
  
              {/* {isPageAdmin && adminData && (
                <>
                  <li>
                    <i className="fa-solid fa-phone" />{" "}
                    {adminData.mobile}
                  </li>
                  <li>
                    <i className="fa-solid fa-envelope" />{" "}
                    {adminData.email}
                  </li>
                </>
              )} */}
  
              <li>
                <i className="fa-solid fa-star" />{" "}
                {reviewsData?.length} Reviews
              </li>
            </ul>
          </div>
        </aside>
      </div>
    );
}

export default PageRight;

