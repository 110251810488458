import { useNavigate, useParams } from "react-router-dom"
import { API_URL, toastFunction } from "../../../App"
import { token } from "../../home/Home"
import axios from "axios"

const PageAccess=()=>{
    const params =(window.location.pathname)
    const paramsData = params.split('/')[4]
    const navigate = useNavigate()
    console.log(paramsData,"id");
    

    const AcceptInvitation=()=>{
        axios.get(`${API_URL}/page/invitation/accept/${paramsData}`,{
            headers:{
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
        .then((res)=>{
           setTimeout(()=>{
            navigate(`/user/page/${res?.data?.page?._id}`,{replace:true})
           },500)
           
        })
        .catch((err)=>{
            toastFunction(err.message)
        })
    }


    return (
        <section className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="max-w-md w-full bg-white shadow-md rounded-lg p-6">
        <div className="2fa">
             {/* <div className="flex justify-center items-center">
               
             </div> */}
             <p className="text-blue-500 text-[17px] mt-3 md:m-14 font-semibold">You are requested for page access.</p>
             <div className="flex justify-between mt-5">
               <button className="w-[37%] bg-red-600 text-white">Deny</button>
               <button className="w-[37%] py-[5px] bg-green-600 text-white" onClick={()=>AcceptInvitation()}>Approve</button>
             </div>
           </div>
        </div>
     </section>  
    )
}

export default PageAccess