import { useRef, useState } from "react";
import { API_URL } from "../../../App";
import { token } from "../../home/Home";
import "./PageUpload.css"
import { useParams } from "react-router-dom";

const PageUpload = () => {
    const photoInputRef = useRef(null);
    const videoInputRef = useRef(null);
    const [imageFile, setImageFile] = useState("")
    const [videoFile, setVideoFile] = useState("");
    const [description, setDescription] = useState("")
    const { id } = useParams()
   
    
    const handleClick = (ref) => {
        ref.current.click();
    };


    const HandlePost = (id) => {
        const fdata = new FormData()
        fdata.append("description", description);
        fdata.append("image", imageFile);
        fdata.append("video", videoFile);

        fetch(`${API_URL}/page-post/create-post/${id}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`
            },
            body: fdata
        })
            .then((res) => {
                res.json()
            })
            .then((data) => {

                window.location.reload()

                // if(data.status==true){
                //     window.location.reload()

                // }
                // else{
                //     alert(data.messege)
                // }

            })
            .catch((err) => {
                alert(err)
            })
        setImageFile("")
        setVideoFile("")
        setDescription("")
    }



    return (
        <div className="news-feed news-feed-form">
            <h3 className="news-feed-title">Create New Post</h3>

            <form >
                <div className="form-group">
                    <textarea name="message" className="form-control"
                        placeholder="Write something here..." value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
                </div>
                {imageFile && (
                    <div className="shareImgContainer">
                        <div className='img_container'>
                            <img className="shareImg" src={URL.createObjectURL(imageFile)} alt="" />
                        </div>

                        <br />
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-warning" type="button" style={{ margin: "10px", backgroundColor: "#FFC107" }} onClick={() => setImageFile(null)}>CANCEL</button>

                            <button className="btn btn-primary" type="button" style={{ margin: "10px", backgroundColor: "blue" }} onClick={() => HandlePost(id)}>POST</button>
                        </div>
                    </div>
                )}
                {videoFile && (
                    <div className="shareImgContainer">
                        <div className="shareVideo">
                            <video controls autoplay>
                                <source src={URL.createObjectURL(videoFile)} type="video/mp4" />
                            </video>
                        </div>
                        <br />
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-warning" type="button" style={{ margin: "10px" }} onClick={() => setVideoFile(null)}>cancel</button>
                            <button className="btn btn-primary" type="button" style={{ margin: "10px" }} onClick={() => HandlePost(id)}>Post</button>
                        </div>
                    </div>
                )}
                <ul className="button-group d-flex justify-content-between align-items-center">
                    <input
                        style={{ display: 'none' }}
                        ref={photoInputRef}
                        type="file"
                        onChange={(e) => setImageFile(e.target.files[0])}
                        accept="image/png, image/jpeg"
                    />
                    <li className="photo-btn">
                        <button type="button" onClick={() => handleClick(photoInputRef)}><i className="flaticon-gallery"></i> Photo</button>
                    </li>
                    <li className="video-btn">
                        <input
                            style={{ display: 'none' }}
                            ref={videoInputRef}
                            type="file"
                            onChange={(e) => {
                                setVideoFile(e.target.files[0]);

                            }}
                            accept="video/mp4"
                        />
                        <button type="button" onClick={() => handleClick(videoInputRef)}><i className="flaticon-video"></i> Video</button>
                    </li>
                    <li className="tag-btn">
                        <button type="submit"><i className="flaticon-tag"></i> Tag Friends</button>
                    </li>
                    <li className="post-btn">
                    </li>
                </ul>
            </form>
        </div>
    )
}

export default PageUpload