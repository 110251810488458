import React, { useEffect, useState } from "react";
import { toast } from "react-toastify"
import { io } from "socket.io-client";
import {
  HMSRoomState,
  selectIsConnectedToRoom,
  selectRoomState,
  useHMSActions,
  useHMSStore,
} from "@100mslive/react-sdk";
import { Loader } from "../src/video_chat/Loader";
import AllRoute from "./utils/AllRoute";
import { GetPagesByUser } from "./apiCall/GetAllFrindsList";
import { getCurrentUser } from "./Page-redux/commonPageReducer/action";
import { useDispatch } from "react-redux";
export const API_URL ="https://myyearbook.in:8000";
const user = JSON?.parse(localStorage?.getItem("user")) || null


export const toastFunction = (messages
) => {
  return toast(messages, {
    position: "top-center",
    autoClose: 4000,
    hideProgressBar: true,
    closeButton: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  })
}

const loadingStates = [HMSRoomState.Connecting, HMSRoomState.Disconnecting];

export default function App() {

  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const roomState = useHMSStore(selectRoomState);
  const hmsActions = useHMSActions();
  const [data,setData]=useState([]);
  const [socket,setSocket] = useState(null);
  const [isPageDataLoaded, setIsPageDataLoaded] = useState(false);

  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);



    useEffect(()=>{
    const fetchPageData = async () => {
      try {
        const response = await GetPagesByUser("managed");
        setData(response?.pages || []);
      } catch (err) {
        console.error("Error fetching page data:", err);
      } finally {
        setIsPageDataLoaded(true);
      }
    };

    if (user?._id) {
      fetchPageData();
    }
  }, []);

    const userPageData = data?.find((ele)=>ele)

  useEffect(() => {

    if (!user?._id || !isPageDataLoaded) {
      return;
    }

    if(socket){
      socket.disconnect();
    }

    const connectionQuery = {
      id:user._id,
      ...(userPageData?._id && {pageId: userPageData._id})
    }


    if (userPageData?._id || user) {
        const newSocket = io(`${API_URL}/notification`, {
          query:connectionQuery
        });
  
        newSocket.on('connect', () => {
          // console.log('Connected to notification server');
          console.log('Connected to notification server with pageId:', userPageData?._id);
          const userId = user?._id; // Replace 'userId' with the actual user ID
          newSocket.emit('connection', { id: userId , pageId: userPageData?._id });
        });
    
     

      newSocket.on('disconnect', () => {
        // console.log('Disconnected from notification server');
      });

      newSocket.on('notification', (data) => {
        // Handle different types of notifications
        console.log(data,"e")
        if (data.notificationType === 'Page') {
          toastFunction(`${data.senderName} ${data.message} on your page`);
        } else {
          toastFunction(`${data.senderName} ${data.message}`);
        }
      });

      setSocket(newSocket);

    }
  }, [user?._id,userPageData?._id,isPageDataLoaded]);


  useEffect(() => {
    window.onbeforeunload = () => {
      if (isConnected) {
        hmsActions.leave();
      }
    };
  }, [hmsActions, isConnected]);

  if (loadingStates.includes(roomState) || !roomState) {
    return <Loader />;
  }

  return (
    <>

      <AllRoute />

    </>
  );
}
