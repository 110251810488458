import { useEffect, useState } from "react"
import { API_URL } from "../../../App"
import { token } from "../../home/Home"
import ImageCard from "./imageCard"
import VideoCard from "./videoCard"
import { useParams } from "react-router-dom"

const GetPagePost=()=>{
    const [data,setData]=useState([])
    const {id}=useParams()

    const GetData=()=>{

        fetch(`${API_URL}/page-post/${id}?type=all`,{
            method:"GET",
            headers:{
                "Content-Type":"application/json",
                "Authorization":`Bearer ${token}`
            }
        })
        .then((res)=>{
                return res.json()
        })
        .then((data)=>{
            
            setData(data.pagePosts)
            console.log(data.pagePosts,"sdf")
        })

    }

    useEffect(()=>{
        GetData()
    },[id])


    return (
        <div>
          {
            data && data.map((ele)=>(
                    <div key={ele._id}>
                         {
                                ele.image?<ImageCard postItem={ele}/>:<VideoCard postItem={ele}/>
                         }   
                    </div>
            ))
          }
        </div>
    )
}

export default GetPagePost