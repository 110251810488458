import "./PageLeft.css"
import explore_1 from "../../../assets/images/explore-events/explore-1.jpg"
import explore_2 from "../../../assets/images/explore-events/explore-2.jpg"



const PageLeft=()=>{


    return (
        <div className="col-lg-3 col-md-12">
            <aside className="widget-area-page">
            <div className="widget widget-explore-events-page">
                        <p className="widget-title-page" >Explore Events</p>
                        <article className="item">
                            <a href="#"><img src={explore_1} alt="image" /></a>
                        </article>
                        <article className="item">
                            <a href="#"><img src={explore_2} alt="image" /></a>
                        </article>
                    </div>
            </aside>
        </div>
    )
}

export default PageLeft